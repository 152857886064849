import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const WindIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <g>
      <path
        fill="none"
        stroke="#F0F0F0"
        strokeWidth={2.4349}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M51.6,34.9H2.3"
      />
      <path
        fill="none"
        stroke="#F0F0F0"
        strokeWidth={2.4349}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M44.4,17.6c1.8-1.8,4.3-3,7.1-3c5.6,0,10.1,4.5,10.1,10.1s-4.5,10.1-10.1,10.1"
      />
      <path
        fill="none"
        stroke="#F0F0F0"
        strokeWidth={2.4349}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M27.2,49.8c0.9,0.9,2.2,1.5,3.6,1.5c2.8,0,5-2.3,5-5s-2.3-5-5-5H2.3"
      />
      <path
        fill="none"
        stroke="#F0F0F0"
        strokeWidth={2.4349}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M24.9,15.7c1.4-1.4,3.3-2.2,5.4-2.2c4.2,0,7.6,3.4,7.6,7.6s-3.4,7.6-7.6,7.6H2.3"
      />
    </g>
  </g>,
  'WindIcon',
)
WindIcon.defaultProps = {
  viewBox: '0 0 64 64',
}
WindIcon.displayName = 'WindIcon'
