import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  BoxProps,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardContentProps,
  CardProps,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material'

import Decimal from 'decimal.js'
import { FC, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { TrailSystem } from '../../models'
import { TrailSystemStatus, TriangleIcon } from '../index'

const StyledCard = styled(Card)<CardProps>({
  '& .MuiCardActionArea-root': {
    height: '100%',
  },
  height: '100%',
})

const StyledCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'mini',
})<CardContentProps & { mini?: boolean }>(({ mini }) => ({
  '&:last-child': {
    paddingBottom: mini ? 8 : undefined,
  },
  padding: mini ? 8 : undefined,
  paddingBottom: 0,
}))

const FreezeThawBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'mini',
})<BoxProps & { mini?: boolean }>(({ theme, mini }) => ({
  color: theme.palette.freezeThaw.main,
  fontSize: mini ? 20 : 26,
  position: 'relative',
}))

const FreezeThawWarningLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'mini',
})<TypographyProps & { mini?: boolean }>(({ theme, mini }) => ({
  color: theme.palette.freezeThaw.contrastText,
  fontSize: mini ? 10 : 13,
  fontWeight: 700,
  left: '50%',
  letterSpacing: 0,
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}))

const SponsorLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'mini',
})<TypographyProps & { mini?: boolean }>(({ theme, mini }) => ({
  color: theme.palette.grey[400],
  fontSize: mini ? 12 : 16,
  textAlign: 'center',
  whiteSpace: 'normal',
}))

type TrailSystemCardProps = {
  trailSystem: TrailSystem
  mini?: boolean
  embedded?: boolean
}

export const TrailSystemCard: FC<TrailSystemCardProps> = (props) => {
  const location = useLocation()

  const trailStats = useMemo(() => {
    const initialStats = {
      closed: 0,
      count: 0,
      fair: 0,
      firm: 0,
      miles: 0,
      rutty: 0,
    }
    return props.trailSystem.trails
      ? props.trailSystem.trails.reduce(
          (acc, trail) => ({
            closed: new Decimal(acc.closed).add(trail.status?.status === 0 ? 1 : 0).toNumber(),
            count: new Decimal(acc.count).add(1).toNumber(),
            fair: new Decimal(acc.fair).add(trail.status?.status === 2 ? 1 : 0).toNumber(),
            firm: new Decimal(acc.firm).add(trail.status?.status === 3 ? 1 : 0).toNumber(),
            miles: new Decimal(acc.miles).add(trail.distance).toDecimalPlaces(1).toNumber(),
            rutty: new Decimal(acc.rutty).add(trail.status?.status === 1 ? 1 : 0).toNumber(),
          }),
          initialStats,
        )
      : initialStats
  }, [props.trailSystem.trails])

  const trailFreezeThawStats = useMemo(() => {
    const initialStats = {
      closed: 0,
      count: 0,
      fair: 0,
      firm: 0,
      miles: 0,
      rutty: 0,
    }
    return props.trailSystem.trails
      ? props.trailSystem.trails.reduce((acc, trail) => {
          const isFreezeThaw = trail.status?.freezeThawStatus?.status && trail.status?.freezeThawStatus?.status > 0
          return {
            closed: new Decimal(acc.closed).add(isFreezeThaw && trail.status?.status === 0 ? 1 : 0).toNumber(),
            count: new Decimal(acc.count).add(isFreezeThaw ? 1 : 0).toNumber(),
            fair: new Decimal(acc.fair).add(isFreezeThaw && trail.status?.status === 2 ? 1 : 0).toNumber(),
            firm: new Decimal(acc.firm).add(isFreezeThaw && trail.status?.status === 3 ? 1 : 0).toNumber(),
            miles: new Decimal(acc.miles)
              .add(isFreezeThaw ? trail.distance : 0)
              .toDecimalPlaces(1)
              .toNumber(),
            rutty: new Decimal(acc.rutty).add(isFreezeThaw && trail.status?.status === 1 ? 1 : 0).toNumber(),
          }
        }, initialStats)
      : initialStats
  }, [props.trailSystem.trails])

  return (
    <StyledCard>
      <CardActionArea
        href={`/trail-system/${props.trailSystem.permalink}${location.search}`}
        target={props.embedded ? '_blank' : undefined}
      >
        <Stack justifyContent="space-between" width="100%" height="100%">
          <StyledCardContent mini={props.mini}>
            <Stack alignItems="center" spacing={props.mini ? 1 : 2}>
              <Stack alignItems="stretch" width="100%">
                <Stack direction="row" justifyContent="space-between" alignItems="start">
                  <Stack>
                    <Typography component="h2" variant="h5" fontWeight={700} fontSize={props.mini ? 16 : 24}>
                      {props.trailSystem.name}
                    </Typography>
                    <Typography component="h3" variant="subtitle2" fontSize={props.mini ? 12 : 14}>
                      {trailStats.miles} miles of trails
                    </Typography>
                  </Stack>
                  {trailFreezeThawStats.count > 0 && (
                    <Tooltip
                      title={`${trailFreezeThawStats.count} trail${trailFreezeThawStats.count > 1 ? 's' : ''} within this system ${trailFreezeThawStats.count > 1 ? 'are' : 'is'} currently experiencing freeze / thaw conditions and ${trailFreezeThawStats.count > 1 ? 'are' : 'is'} not included in the status rings below.`}
                    >
                      <FreezeThawBox mini={props.mini}>
                        <TriangleIcon fontSize="inherit" />
                        <FreezeThawWarningLabel>{trailFreezeThawStats.count}</FreezeThawWarningLabel>
                      </FreezeThawBox>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
              <TrailSystemStatus
                values={{
                  closed: trailStats.closed,
                  fair: trailStats.fair - trailFreezeThawStats.fair,
                  firm: trailStats.firm - trailFreezeThawStats.firm,
                  rutty: trailStats.rutty - trailFreezeThawStats.rutty,
                }}
                // test values to show all rings full
                // values={{
                //   closed: 5,
                //   fair: 5,
                //   firm: 5,
                //   rutty: 5,
                // }}
                count={trailStats.count}
                mini={props.mini}
              />
              {!!props.trailSystem.sponsor && (
                <Stack alignItems="center" justifyContent="center" width="100%">
                  <SponsorLabel mini={props.mini}>Powered by {props.trailSystem.sponsor.name}</SponsorLabel>
                </Stack>
              )}
            </Stack>
          </StyledCardContent>
          {!props.mini && (
            <CardActions>
              <Stack direction="row" justifyContent="center" width="100%">
                <IconButton aria-label="View Trail System" size="medium">
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </CardActions>
          )}
        </Stack>
      </CardActionArea>
    </StyledCard>
  )
}
TrailSystemCard.displayName = 'TrailSystemCard'
