import { SvgIconProps } from '@mui/material'

import { FC } from 'react'

import {
  BrokenCloudsDayIcon,
  BrokenCloudsNightIcon,
  ClearSkyDayIcon,
  ClearSkyNightIcon,
  LowVisibilityIcon,
  OvercastCloudsIcon,
  RainDayIcon,
  RainNightIcon,
  ScatteredCloudsDayIcon,
  ScatteredCloudsNightIcon,
  SnowIcon,
  ThunderstormIcon,
  TornadoIcon,
  WindIcon,
} from '.'

type WeatherIconProps = SvgIconProps & {
  conditionId: number
  cycle?: 'Day' | 'Night'
}

export const WeatherIcon: FC<WeatherIconProps> = (props) => {
  const { conditionId: _, cycle: __, ...rest } = props
  switch (props.conditionId) {
    /** Thunderstorms */
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
      return <ThunderstormIcon {...rest} />
    /** Rain */
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
      return props.cycle === 'Night' ? <RainNightIcon {...rest} /> : <RainDayIcon {...rest} />
    /** Snow */
    case 30:
    case 31:
    case 32:
    case 33:
    case 34:
    case 35:
    case 36:
    case 37:
    case 38:
    case 39:
    case 40:
      return <SnowIcon {...rest} />
    /** Low Visibility */
    case 41:
    case 42:
    case 43:
    case 44:
    case 45:
    case 46:
    case 47:
    case 55:
      return <LowVisibilityIcon {...rest} />
    /** Winds */
    case 48:
      return <WindIcon {...rest} />
    /** Tornados */
    case 49:
      return <TornadoIcon {...rest} />
    /** Clear Skies */
    case 50:
      return props.cycle === 'Night' ? <ClearSkyNightIcon {...rest} /> : <ClearSkyDayIcon {...rest} />
    /** Scattered Clouds */
    case 51:
    case 52:
      return props.cycle === 'Night' ? <ScatteredCloudsNightIcon {...rest} /> : <ScatteredCloudsDayIcon {...rest} />
    /** Broken Clouds */
    case 53:
      return props.cycle === 'Night' ? <BrokenCloudsNightIcon {...rest} /> : <BrokenCloudsDayIcon {...rest} />
    /** Overcast Clouds */
    case 54:
      return <OvercastCloudsIcon {...rest} />
    default:
      return <></>
  }
}
WeatherIcon.displayName = 'WeatherIcon'
