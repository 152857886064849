import dayjs from 'dayjs'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Helmet } from 'react-helmet'

import App from './App'

dayjs.extend(isLeapYear)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Helmet>
      <title>Flowfeed - Real trails. Real conditions. Real time.</title>
      <meta property="og:title" content="Flowfeed - Real trails. Real conditions. Real time." />
      <meta name="twitter:title" content="Flowfeed - Real trails. Real conditions. Real time." />
    </Helmet>
    <App />
  </React.StrictMode>,
)
