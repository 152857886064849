import { Box, BoxProps, Link, Stack, styled } from '@mui/material'

import { FC } from 'react'

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  letterSpacing: '.1rem',
  [theme.breakpoints.down(420)]: {
    fontSize: 12,
    letterSpacing: 0,
  },
}))

export const AppAlert: FC = () => {
  return (
    <StyledBox>
      {!!process.env.REACT_APP_ALERT_ACTIVE &&
        !!process.env.REACT_APP_ALERT_TEXT &&
        (process.env.REACT_APP_ALERT_HREF ? (
          <Stack
            component={Link}
            letterSpacing="inherit"
            href={process.env.REACT_APP_ALERT_HREF}
            alignItems="center"
            direction="row"
            justifyContent="center"
            width="100%"
            dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_ALERT_TEXT }}
          ></Stack>
        ) : (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            width="100%"
            dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_ALERT_TEXT }}
          ></Stack>
        ))}
    </StyledBox>
  )
}
AppAlert.displayName = 'AppAlert'
