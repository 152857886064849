import Decimal from 'decimal.js'

export const conjunctArray = (arr: string[], conjunction = 'and') => {
  if (!arr?.length) {
    return ''
  }
  if (arr.length === 1) {
    return arr[0]
  }
  if (arr.length === 2) {
    return arr.join(` ${conjunction} `)
  }
  return `${arr.slice(0, -1).join(', ')}, ${conjunction} ${arr.slice(-1)}`
}

export const getObjectKeyFromValue = (obj: Record<string, unknown>, value: unknown): string | undefined => {
  return Object.keys(obj)[Object.values(obj).indexOf(value)]
}

export function convertArrayToEnum<T>(enumType: Record<string, unknown>, arr: unknown[]): T[] {
  return arr.filter((value) => Object.values(enumType).includes(value as T)) as unknown as T[]
}

export function safeJsonParse<T>(json: string, defaultValue: T): T {
  try {
    return JSON.parse(json) as T
  } catch {
    return defaultValue
  }
}

export const convertCelsiusToFahrenheit = (celsius: number | undefined) => {
  return celsius !== undefined ? new Decimal(celsius).mul(9).div(5).add(32).toNumber() : -1
}

export const convertDegreesToDirection = (degree?: number) => {
  let direction: 'N' | 'NE' | 'E' | 'SE' | 'S' | 'SW' | 'W' | 'NW' = 'N'
  if (degree && degree >= 0 && degree <= 360) {
    if ((degree >= 0 && degree <= 22.5) || (degree > 337.5 && degree <= 360)) {
      direction = 'N'
    } else if (degree > 22.5 && degree <= 67.5) {
      direction = 'NE'
    } else if (degree > 67.5 && degree <= 112.5) {
      direction = 'E'
    } else if (degree > 112.5 && degree <= 157.5) {
      direction = 'SE'
    } else if (degree > 157.5 && degree <= 202.5) {
      direction = 'S'
    } else if (degree > 202.5 && degree <= 247.5) {
      direction = 'SW'
    } else if (degree > 247.5 && degree <= 292.5) {
      direction = 'W'
    } else if (degree > 292.5 && degree <= 337.5) {
      direction = 'NW'
    }
  }

  return direction
}

export const convertMetersPerSecToMilesPerHour = (metersPerSec: number) => {
  return metersPerSec * 2.2369
}
