import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const ClearSkyNightIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <circle fill="#BFE5EF" cx="32" cy="32" r="30" />
  </g>,
  'ClearSkyNightIcon',
)
ClearSkyNightIcon.defaultProps = {
  viewBox: '0 0 64 64',
}
ClearSkyNightIcon.displayName = 'ClearSkyNightIcon'
