import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const LowVisibilityIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <g id="MIST">
      <g>
        <path
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.0835}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M62.1,50.2c0,0-3.8,1.8-5,1.8c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2c0,0-1.4-0.7-2.7-0.7c-1.3,0-2.7,0.7-2.7,0.7 c-1.3,0.6-3.4,1.6-4.7,2.2c0,0-1.4,0.7-2.7,0.7c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2c0,0-1.4-0.7-2.7-0.7 c-1.3,0-2.7,0.7-2.7,0.7c-1.3,0.6-3.4,1.6-4.7,2.2c0,0-1.4,0.7-2.7,0.7c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2 c0,0-1.4-0.7-2.6-0.7c-1.3,0-5,1.8-5,1.8"
        />
      </g>
      <g>
        <path
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.0835}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M62.1,38.1c0,0-3.8,1.8-5,1.8c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2c0,0-1.4-0.7-2.7-0.7c-1.3,0-2.7,0.7-2.7,0.7 c-1.3,0.6-3.4,1.6-4.7,2.2c0,0-1.4,0.7-2.7,0.7c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2c0,0-1.4-0.7-2.7-0.7 c-1.3,0-2.7,0.7-2.7,0.7c-1.3,0.6-3.4,1.6-4.7,2.2c0,0-1.4,0.7-2.7,0.7c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2 c0,0-1.4-0.7-2.6-0.7c-1.3,0-5,1.8-5,1.8"
        />
      </g>
      <g>
        <path
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.0835}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M62.1,25.9c0,0-3.8,1.8-5,1.8c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2c0,0-1.4-0.7-2.7-0.7c-1.3,0-2.7,0.7-2.7,0.7 c-1.3,0.6-3.4,1.6-4.7,2.2c0,0-1.4,0.7-2.7,0.7c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2c0,0-1.4-0.7-2.7-0.7 c-1.3,0-2.7,0.7-2.7,0.7c-1.3,0.6-3.4,1.6-4.7,2.2c0,0-1.4,0.7-2.7,0.7c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2 c0,0-1.4-0.7-2.6-0.7c-1.3,0-5,1.8-5,1.8"
        />
      </g>
      <g>
        <path
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.0835}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M62.1,13.8c0,0-3.8,1.8-5,1.8c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2c0,0-1.4-0.7-2.7-0.7c-1.3,0-2.7,0.7-2.7,0.7 c-1.3,0.6-3.4,1.6-4.7,2.2c0,0-1.4,0.7-2.7,0.7c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2c0,0-1.4-0.7-2.7-0.7 c-1.3,0-2.7,0.7-2.7,0.7c-1.3,0.6-3.4,1.6-4.7,2.2c0,0-1.4,0.7-2.7,0.7c-1.3,0-2.7-0.7-2.7-0.7c-1.3-0.6-3.4-1.6-4.7-2.2 c0,0-1.4-0.7-2.6-0.7c-1.3,0-5,1.8-5,1.8"
        />
      </g>
    </g>
  </g>,
  'LowVisibilityIcon',
)
LowVisibilityIcon.defaultProps = {
  viewBox: '0 0 64 64',
}
LowVisibilityIcon.displayName = 'LowVisibilityIcon'
