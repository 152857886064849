import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const TriangleIcon: FC<SvgIconProps> = createSvgIcon(
  <path d="M6.9,91.9h86.3c3.7,0,6.1-4.1,4.2-7.3L54.2,10.1c-1.9-3.2-6.5-3.2-8.4,0L2.7,84.6C0.8,87.8,3.1,91.9,6.9,91.9z" />,
  'TriangleIcon',
)
TriangleIcon.defaultProps = {
  viewBox: '0 0 100 100',
}
TriangleIcon.displayName = 'TriangleIcon'
