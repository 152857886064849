import { QueryKey, useSuspenseQuery, UseSuspenseQueryOptions } from '@tanstack/react-query'

import {
  // Difficulties,
  TrailFinderQuestion,
} from '../models'
import { ApiQueryService } from '../services'

export function useTrailFinderQuestions(
  config?: Omit<
    UseSuspenseQueryOptions<TrailFinderQuestion[], Error, TrailFinderQuestion[], QueryKey>,
    'queryFn' | 'queryKey'
  >,
) {
  return useSuspenseQuery<TrailFinderQuestion[]>({
    ...config,
    queryFn: async () =>
      new ApiQueryService().get<TrailFinderQuestion[]>({
        endpoint: 'trail-finder-question/list',
      }),
    queryKey: ['trail-finder-question/list'],
    // Override answer filters for testing
    // select: (data) => {
    //   if (data?.[1]?.answers?.[0]?.affectedFilters) {
    //     data[1].answers[0].affectedFilters = {
    //       ...data[1].answers[0].affectedFilters,
    //       difficulties: [Difficulties.Blue, Difficulties.Green],
    //       radius: [20],
    //       statuses: [0, 1, 2, 3],
    //       surfaceTypes: ['Sealed Aggregate'],
    //     }
    //   }
    //   return data
    // },
  })
}
