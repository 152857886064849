import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material'

import { FC, useMemo } from 'react'

import { getObjectKeyFromValue } from '../../utils'
import { DifficultyIcon } from './..'
import { StatusDescriptions, StatusEnum, Trail, TrailSystem } from './../../models'

const StyledCard = styled(Card)(() => ({
  '& .MuiCardActionArea-root': {
    height: '100%',
  },
  height: '100%',
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingBottom: 0,
}))

const StatusLabel = styled(Typography)<TypographyProps>({
  fontWeight: 700,
  left: '50%',
  marginTop: '-0.2em',
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(-50%, -50%)',
})

type TrailCardProps = {
  trail: Trail
  trailSystem: TrailSystem
}

export const TrailCard: FC<TrailCardProps> = (props) => {
  const theme = useTheme()

  const isFreezeThaw = useMemo(
    () =>
      props.trail.status?.status !== 0 &&
      props.trail.status?.freezeThawStatus?.status &&
      props.trail.status?.freezeThawStatus?.status > 0,
    [props.trail.status?.status, props.trail.status?.freezeThawStatus?.status],
  )

  return (
    <StyledCard>
      <CardActionArea href={`/trail-system/${props.trailSystem.permalink}/${props.trail.permalink}${location.search}`}>
        <Stack justifyContent="space-between" width="100%" height="100%">
          <StyledCardContent>
            <Stack alignItems="center" spacing={2}>
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Stack width="100%" minHeight="4.5em">
                  <Typography component="h3" variant="body1">
                    {props.trail.name}
                  </Typography>
                  <Typography component="p" variant="body2">
                    {Math.round(props.trail.distance * 10) / 10} miles
                  </Typography>
                </Stack>
                <DifficultyIcon difficultyId={props.trail.difficultyId} fontSize={18} />
              </Stack>
              <Tooltip
                title={
                  isFreezeThaw
                    ? 'Soil temps are fluctuating above and below the freezing point, leading to variable conditions. Trails may be firm and rideable under 30° F.'
                    : {
                        ...StatusDescriptions,
                        0: props.trail.manualTrailStatuses?.[0].reason || StatusDescriptions[0],
                      }[props.trail.status?.status ?? -1]
                }
              >
                <Box position="relative">
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    size={110}
                    thickness={5}
                    sx={{
                      color: isFreezeThaw
                        ? theme.palette.freezeThaw.main
                        : {
                            [-1]: theme.palette.grey[600],
                            0: theme.palette.text.primary,
                            1: theme.palette.trailStatus.dark,
                            2: theme.palette.trailStatus.main,
                            3: theme.palette.trailStatus.light,
                          }[props.trail.status?.status ?? -1],
                    }}
                  />
                  <StatusLabel
                    component="p"
                    variant="h6"
                    fontSize={isFreezeThaw ? 16 : undefined}
                    marginTop={isFreezeThaw ? '-0.1em' : undefined}
                  >
                    {isFreezeThaw ? 'Freeze Thaw' : getObjectKeyFromValue(StatusEnum, props.trail.status?.status ?? -1)}
                  </StatusLabel>
                </Box>
              </Tooltip>
            </Stack>
          </StyledCardContent>
          <CardActions>
            <Stack direction="row" justifyContent="center" width="100%">
              <IconButton aria-label="View Trail" size="medium">
                <AddIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </CardActions>
        </Stack>
      </CardActionArea>
    </StyledCard>
  )
}
TrailCard.displayName = 'TrailCard'
