import { Difficulties, Status, TrailSystem } from '../../models'

export type TrailSystemFilterParams = {
  term?: string
  difficultyIds?: Difficulties[]
  featureIds?: number[]
  statuses?: Status[]
  surfaceTypes?: string[]
  radius?: number
}

/**
 * Calculates the distance between pointA and pointB.
 * SOURCE: https://www.movable-type.co.uk/scripts/latlong.html
 * @param pointA obj containing lat: number and long: number
 * @param pointB obj containing lat: number and long: number
 *
 * @returns Distance in miles
 */
export const calcCoordDistance = (
  pointA: { lat: number; long: number },
  pointB: { lat: number; long: number },
): number => {
  const R = 6371e3 // metres
  const φ1 = (pointA.lat * Math.PI) / 180 // φ, λ in radians
  const φ2 = (pointB.lat * Math.PI) / 180
  const Δφ = ((pointB.lat - pointA.lat) * Math.PI) / 180
  const Δλ = ((pointB.long - pointA.long) * Math.PI) / 180

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const d = R * c * 0.000621371192 // in miles
  return d
}

export const trailSystemFilter = (
  trailSystems: TrailSystem[],
  filterParams: TrailSystemFilterParams,
  userCoords?: { lat: number; long: number },
) => {
  return (
    trailSystems
      .map((trailSystem) => {
        const trailDistanceMap = new Map<number, number>()
        const filteredTrails = trailSystem.trails?.filter((trail) => {
          if (filterParams?.difficultyIds?.length) {
            if (!filterParams.difficultyIds.includes(trail.difficultyId)) {
              return false
            }
          }
          if (filterParams?.featureIds?.length) {
            if (!filterParams.featureIds.every((featureId) => !!trail.features?.find((f) => f.id === featureId))) {
              return false
            }
          }
          if (filterParams?.statuses?.length) {
            if (!trail.status) {
              return false
            }
            if (!filterParams.statuses.includes(trail.status.status)) {
              return false
            }
          }
          if (filterParams?.surfaceTypes?.length) {
            if (!filterParams.surfaceTypes.includes(trail.surfaceType)) {
              return false
            }
          }
          if (filterParams?.radius && userCoords) {
            const distanceToResult = calcCoordDistance(userCoords, {
              lat: trail.parkingGpsLat,
              long: trail.parkingGpsLong,
            })
            trailDistanceMap.set(trail.id, distanceToResult)
            if (distanceToResult > filterParams.radius) {
              return false
            }
          }
          return true
        })

        return {
          ...trailSystem,
          trails: filteredTrails?.map((trail) => ({
            ...trail,
            distanceFromMe: trailDistanceMap.get(trail.id),
          })),
        }
      })
      .filter((trailSystems) => trailSystems.trails?.length)
      // separate filter for term to ensure we don't include non-matching trails just because the trail system matches term
      .map((trailSystem) => {
        const formattedTerm = filterParams?.term?.trim().toLowerCase()
        if (
          formattedTerm &&
          (trailSystem.name.trim().toLowerCase().includes(formattedTerm) ||
            trailSystem.city.trim().toLowerCase().includes(formattedTerm))
        ) {
          // if the trail system matches, we include all trails for that system that passed the other filters
          return trailSystem
        }

        // if the trail system didn't match the term, we can now filter the trails.
        const filteredTrails = trailSystem.trails?.filter((trail) => {
          if (formattedTerm && !trail.name.trim().toLowerCase().includes(formattedTerm)) {
            return false
          }
          return true
        })

        return {
          ...trailSystem,
          trails: filteredTrails?.map((trail) => ({
            ...trail,
          })),
        }
      })
      .filter((trailSystems) => trailSystems.trails?.length)
  )
}
