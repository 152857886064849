import CollectionsIcon from '@mui/icons-material/Collections'
import PlaceIcon from '@mui/icons-material/Place'
import RouteIcon from '@mui/icons-material/Route'
import { Link, LinkProps, Stack, StackProps, styled, Typography } from '@mui/material'

import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { TrailSystemContext } from '../../providers'

const AutocompleteStack = styled(Stack)(({ theme }) => ({
  background: theme.palette.grey[900],
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[4],
  left: theme.spacing(4),
  position: 'absolute',
  right: theme.spacing(4),
  top: '100%',
  [theme.breakpoints.down('sm')]: {
    left: theme.spacing(1),
    right: theme.spacing(1),
  },
}))

const AutocompleteLink = styled(Stack)<StackProps & LinkProps>(({ theme }) => ({
  '&:hover': {
    background: theme.palette.grey[700],
  },
  color: '#fff',
  padding: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.25),
  paddingTop: theme.spacing(1.25),
}))

type AutocompleteTrailSearchDropdownProps = {
  queryString: string
  trailSystemId?: number
  searchInputRef?: React.RefObject<HTMLInputElement>
  onClick?: () => void
}

export const AutocompleteTrailSearchDropdown: FC<AutocompleteTrailSearchDropdownProps> = (props) => {
  const trailSystemContext = useContext(TrailSystemContext)

  const [isOpen, setIsOpen] = useState(false)

  const dataSet = useMemo(() => {
    return (
      (props.trailSystemId
        ? trailSystemContext.query?.data?.filter((trailSystem) => trailSystem.id === props.trailSystemId)
        : trailSystemContext.query?.data) || []
    )
  }, [props.trailSystemId, trailSystemContext.query?.data])

  // open the dropdown if the search input has a value and the user is focused on the search input
  // ensure we don't open the dropdown on component mount unless the search input is focused and has a value
  useEffect(() => {
    if (props.searchInputRef?.current && props.searchInputRef.current === document.activeElement) {
      setIsOpen(Boolean(props.queryString))
    }
  }, [props.queryString, props.searchInputRef])

  // close the dropdown if the user clicks outside the dropdown or blurs the search input or presses escape
  const handleDocumentClick = useCallback(
    (event: MouseEvent) => {
      if (props.searchInputRef?.current && event.target instanceof Node) {
        if (props.searchInputRef.current.contains(event.target)) {
          setIsOpen(true)
        } else {
          setIsOpen(false)
        }
      }
    },
    [props.searchInputRef],
  )
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [handleDocumentClick])

  // close the dropdown on escape key press
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === 'Escape' ||
        (props.searchInputRef?.current &&
          event.target instanceof Node &&
          props.searchInputRef.current.contains(event.target) &&
          event.key === 'Enter')
      ) {
        setIsOpen(false)
      } else {
        setIsOpen(!!props.queryString)
      }
    },
    [isOpen, setIsOpen],
  )
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  // This will match trail systems by name, city, and keyword and trails by name, city, and keyword
  // it builds a list of city names, trail system names, and trail names that match the query string
  const matches: {
    id: string | number
    label: string
    link: string
    type: string
  }[] = useMemo(() => {
    const query = String(props.queryString || '').toLowerCase()
    if (!query) {
      return []
    }
    const allMatches: {
      id: string | number
      label: string
      link: string
      type: string
    }[] = []

    dataSet?.forEach((trailSystem) => {
      if (
        // prevent duplicate trail systems
        !allMatches.find((existinMatch) => existinMatch.id === trailSystem.id) &&
        // match trail system name
        trailSystem.name.toLowerCase().includes(query)
      ) {
        allMatches.push({
          id: trailSystem.id,
          label: trailSystem.name,
          link: `/trail-system/${trailSystem.permalink}`,
          type: 'trailSystem',
        })
      }

      if (
        // prevent duplicate city names
        !allMatches.find((existinMatch) => existinMatch.id === trailSystem.city) &&
        String(trailSystem.city || '')
          .toLowerCase()
          .includes(query)
      ) {
        allMatches.push({
          id: trailSystem.city,
          label: trailSystem.city,
          link: `?term=${encodeURIComponent(trailSystem.city)}`,
          type: 'city',
        })
      }

      trailSystem.trails?.forEach((trail) => {
        if (
          // prevent duplicate trails
          !allMatches.find((existinMatch) => existinMatch.id === `${trail.system?.id}_${trail.id}`) &&
          // match trail name
          (trail.name.toLowerCase().includes(query) ||
            // match trail keywords
            trail.keywords?.find((keyword) => keyword.name.toLowerCase().includes(query)))
        ) {
          allMatches.push({
            id: `${trail.system?.id}_${trail.id}`,
            label: trail.name,
            link: `/trail-system/${trailSystem.permalink || ''}/${trail.permalink}`,
            type: 'trail',
          })
        }
      })
    })

    return (
      allMatches
        // only return the first 6 matches
        .splice(0, 6)
    )
  }, [props.queryString, dataSet])

  return !isOpen || !matches.length ? null : (
    <AutocompleteStack>
      {matches.map((match) => (
        <AutocompleteLink
          direction="row"
          alignItems="center"
          spacing={1.5}
          component={Link}
          key={match.id}
          href={match.link}
          onClick={() => {
            setIsOpen(false)
            if (props.onClick instanceof Function) {
              props.onClick()
            }
          }}
        >
          <Stack fontSize={33}>
            {match.type === 'city' ? (
              <PlaceIcon fontSize="inherit" />
            ) : match.type === 'trailSystem' ? (
              <CollectionsIcon fontSize="inherit" />
            ) : (
              <RouteIcon fontSize="inherit" />
            )}
          </Stack>
          <Typography fontSize={20}>{match.label}</Typography>
        </AutocompleteLink>
      ))}
    </AutocompleteStack>
  )
}
AutocompleteTrailSearchDropdown.displayName = 'AutocompleteTrailSearchDropdown'
