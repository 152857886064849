import CloseIcon from '@mui/icons-material/Close'
import FavoriteIcon from '@mui/icons-material/Favorite'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Divider,
  Drawer,
  IconButton,
  lighten,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material'

import { FC, useCallback } from 'react'
import { useToggle } from 'react-use'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    background: theme.palette.grey[900],
    width: 375,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const NavStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const NavSubheader = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const NavSubheaderDivider = styled(Divider)(({ theme }) => ({
  background: lighten(theme.palette.grey[900], 0.5),
}))

const StyledLink = styled(Link)(({ theme }) => ({
  '&:hover': {
    background: lighten(theme.palette.grey[900], 0.05),
    color: theme.palette.primary.main,
  },
  color: theme.palette.text.secondary,
  fontSize: 24,
  padding: theme.spacing(2),
}))

export const AppNav: FC = () => {
  const [open, toggleOpen] = useToggle(false)

  const onOpen = useCallback(() => {
    toggleOpen(true)
  }, [toggleOpen])

  const onClose = useCallback(() => {
    toggleOpen(false)
  }, [toggleOpen])

  return (
    <>
      <IconButton aria-label="menu" size="large" onClick={onOpen}>
        <MenuIcon fontSize="inherit" />
      </IconButton>
      <StyledDrawer open={open} anchor="right" onClose={onClose}>
        <Stack direction="row" justifyContent="end" width="100%">
          <IconButton aria-label="close menu" size="large" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>

        <NavStack alignItems="stretch" spacing={4}>
          <List component="nav">
            <ListItem disablePadding component={StyledLink} href="https://www.flowfeed.app" target="_blank">
              <ListItemText primary="Learn More" />
            </ListItem>
            <Divider />
            <ListItem disablePadding component={StyledLink} href="mailto:team@flowfeed.app">
              <ListItemText primary="Contact" />
            </ListItem>
            <Divider />
            <ListItem disablePadding component={StyledLink} href="/trail-finder" onClick={onClose}>
              <ListItemText primary="Trail Finder" />
            </ListItem>
          </List>

          <List
            component="nav"
            aria-labelledby="heart-app-nav-list-subheader"
            subheader={
              <>
                <NavSubheader direction="row" alignItems="center" id="heart-app-nav-list-subheader">
                  <FavoriteIcon fontSize="small" />
                  <Typography>&nbsp;the app?</Typography>
                </NavSubheader>
                <NavSubheaderDivider />
              </>
            }
          >
            <ListItem
              disablePadding
              component={StyledLink}
              href="https://www.buymeacoffee.com/flowfeed"
              target="_blank"
            >
              <ListItemText primary="Buy us a coffee!" />
            </ListItem>
            <Divider />
            <ListItem
              disablePadding
              component={StyledLink}
              href="https://account.venmo.com/u/flowfeedapp"
              target="_blank"
            >
              <ListItemText primary="Venmo" />
            </ListItem>
            <Divider />
            <ListItem
              disablePadding
              component={StyledLink}
              href="https://www.paypal.com/donate/?hosted_button_id=FQG9QHQ544PE4"
              target="_blank"
            >
              <ListItemText primary="PayPal" />
            </ListItem>
          </List>
        </NavStack>
      </StyledDrawer>
    </>
  )
}
AppNav.displayName = 'AppNav'
