export enum Difficulties {
  White = '1',
  Green = '2',
  Blue = '3',
  Black = '4',
  'Double Black' = '5',
}

export const DifficultyColors = {
  [Difficulties.White]: '#ffffff',
  [Difficulties.Green]: '#69804c',
  [Difficulties.Blue]: '#1591cd',
  [Difficulties.Black]: '#000000',
  [Difficulties['Double Black']]: '#000000',
} as const
