import { FreezeThawStatus } from './FreezeThawStatus.model'
import { WeatherRecord } from './WeatherRecord.model'

export type TrailStatus = {
  date: string
  status: Status
  displayWeather?: WeatherRecord
  precipitationDecayed: number
  precipitationLastTwoHours: number
  precipitationLastFortyEightHours: number
  precipitationLastSevenDays: number
  freezeThawStatus: FreezeThawStatus
}

export type Status = -1 | 0 | 1 | 2 | 3

/* eslint-disable sort-keys/sort-keys-fix */
export const StatusEnum = {
  Firm: 3,
  Fair: 2,
  Rutty: 1,
  Closed: 0,
  Unknown: -1,
} as const
/* eslint-enable sort-keys/sort-keys-fix */

export const StatusDescriptions: Readonly<Record<Status, string>> = {
  [-1]: 'Fail to calculate status.',
  0: 'The trail has been closed by a local trail manager, maintenance crew, or other official stakeholder.',
  1: 'The riding surface may be heavily saturated and could be running extremely slow. We do not advise riding Rutty trails.',
  2: 'The riding surface may have moisture in spots and could be running slightly slower.',
  3: 'The riding surface should be firm and running at full speed.',
}
