import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTopOnNavigate: FC = () => {
  const location = useLocation()
  const [lastPathname, setLastPathname] = useState(location.pathname)

  useEffect(() => {
    if (lastPathname !== location.pathname) {
      window.scrollTo(0, 0)
      setLastPathname(location.pathname)
    }
  }, [location.pathname])

  return null
}
ScrollToTopOnNavigate.displayName = 'ScrollToTopOnNavigate'
