import { Box, CircularProgress, Stack, styled, Tooltip, Typography } from '@mui/material'

import Decimal from 'decimal.js'
import { FC, useMemo } from 'react'

const RingLabel = styled(Typography)(({ theme }) => ({
  bottom: theme.spacing(-0.5),
  left: `calc(50% + ${theme.spacing(2)})`,
  position: 'absolute',
  zIndex: 2,
}))

const RingTransformer = styled(Box)(() => ({
  position: 'relative',
  transform: 'rotate(-180deg)',
  zIndex: 1,
}))

const Ring = styled(CircularProgress)({ component: 'h3' }, () => ({
  '& circle': {
    strokeLinecap: 'round',
  },
}))

type TrailSystemStatusRingProps = {
  color: string
  size: number
  value: number
  total: number
  label: string
  max: number
  tooltip?: string
  scale?: number
}

export const TrailSystemStatusRing: FC<TrailSystemStatusRingProps> = (props) => {
  const value = useMemo(() => {
    const percentLimiter = 91
    return new Decimal(props.value)
      .div(props.total)
      .mul(percentLimiter)
      .mul(new Decimal(percentLimiter).div(new Decimal(props.max).div(props.total).mul(100)))
      .ceil()
      .toNumber()
  }, [props.total, props.value, props.max])

  const scale = useMemo(() => props.scale || 1, [props.scale])

  return (
    <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
      <Stack alignItems="center" justifyContent="center" position="relative" width="100%">
        <RingTransformer>
          <Ring
            variant="determinate"
            value={value}
            size={props.size * scale}
            thickness={500 / props.size}
            sx={{ color: props.color }}
          />
        </RingTransformer>
        <RingLabel variant="body2" fontSize={14 * scale}>
          {props.tooltip ? (
            <Tooltip title={props.tooltip}>
              <span>
                {props.value} {props.label}
              </span>
            </Tooltip>
          ) : (
            <span>
              {props.value} {props.label}
            </span>
          )}
        </RingLabel>
      </Stack>
    </Stack>
  )
}
TrailSystemStatusRing.displayName = 'TrailSystemStatusRing'
