import axios from 'axios'

type GoogleGeoLocationAddressComponent = { long_name?: string; short_name?: string; types?: string[] }

export class UserLocationService {
  private googleApiKey: string

  constructor() {
    this.googleApiKey = process.env.REACT_APP_GOOGLE_GEO_API_KEY || ''
  }

  public async requestGoogleGeoLocation(userCoords: { lat: number; long: number }) {
    const response = (
      await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${userCoords.lat},${userCoords.long}&key=${this.googleApiKey}`,
      )
    )?.data

    if (!response.results || response?.status !== 'OK') {
      throw new Error('Failed to get user locality')
    }

    return this.getLocalityFromGeoResponse(response.results)
  }

  private async getLocalityFromGeoResponse(
    results: {
      address_components?: GoogleGeoLocationAddressComponent[]
    }[],
  ) {
    let locality = ''
    let state = ''
    results?.forEach((result) => {
      if (Array.isArray(result.address_components) && result.address_components.length) {
        const localityAddressComponent = result.address_components.find((addressComponent) =>
          addressComponent.types?.includes('locality'),
        )
        if (localityAddressComponent) {
          locality = localityAddressComponent.long_name || localityAddressComponent.short_name || ''
        }

        const stateAddressComponent = result.address_components.find((addressComponent) =>
          addressComponent.types?.includes('administrative_area_level_1'),
        )
        if (stateAddressComponent) {
          state = stateAddressComponent.long_name || stateAddressComponent.short_name || ''
        }
      }
    })

    return `${locality}${locality.length && state.length ? ', ' + state : state}`
  }
}
