import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const TrailFinderIcon: FC<SvgIconProps> = createSvgIcon(
  <path d="M26.7,37.7V48h-3.5V29.6H10.6l-4-4l4-4h12.7v-6.3V7.2V2h3.5v4h12.7l4,4l-4,4H26.7V25V37.7z" />,
  'TrailFinderIcon',
)
TrailFinderIcon.defaultProps = {
  viewBox: '0 0 50 50',
}
TrailFinderIcon.displayName = 'TrailFinderIcon'
