import CloseIcon from '@mui/icons-material/Close'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import {
  Button,
  ButtonProps,
  Drawer,
  FormControl,
  IconButton,
  MenuItem,
  MenuItemProps,
  OutlinedInput,
  Select,
  SelectProps,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'

import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useToggle } from 'react-use'

import { Difficulties, Status, StatusEnum, TrailFeature } from '../../models'
import { TrailSystemContext } from '../../providers'
import { DifficultyIcon } from './..'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    background: theme.palette.grey[900],
    width: 375,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const DrawerStack = styled(Stack)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 280,
  padding: theme.spacing(2),
  width: '100%',
}))

const FilterSelect = styled(Select)<SelectProps>(({ theme }) => ({
  '& .MuiSelect-select': {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  letterSpacing: '.1em',
  textAlign: 'center',
  textTransform: 'uppercase',
}))

const FilterMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  '&.Mui-selected': {
    borderLeftColor: theme.palette.primary.main,
    borderRightColor: theme.palette.primary.main,
  },
  borderLeft: `${theme.spacing(0.5)} solid transparent`,
  borderRight: `${theme.spacing(0.5)} solid transparent`,
  letterSpacing: '.1em',
  textTransform: 'uppercase',
}))

const FilterButton = styled(Button)<ButtonProps>(() => ({
  fontSize: 16,
  textTransform: 'uppercase',
}))

export const TrailFilterDrawer: FC = () => {
  const [open, toggleOpen] = useToggle(false)
  const [radius, setRadius] = useState<number>(0)
  const trailSystemContext = useContext(TrailSystemContext)

  const allTrailFeatures = useMemo(
    () =>
      Object.values(
        (trailSystemContext.query?.data || [])
          .flatMap((ts) => ts.trails)
          .flatMap((t) => t?.features)
          .filter(Boolean)
          .reduce(
            (acc, f) => ({
              ...acc,
              ...(f?.id && { [f.id]: f }),
            }),
            {} as Record<number, TrailFeature>,
          ),
      ),
    [trailSystemContext.query?.data],
  )

  const allTrailSurfaceTypes = useMemo(
    () =>
      Array.from(
        new Set(
          (trailSystemContext.query?.data || [])
            .flatMap((ts) => ts.trails)
            .map((t) => t?.surfaceType)
            .reduce((acc, st) => (st ? [...acc, st] : acc), [] as string[]),
        ),
      ),
    [trailSystemContext.query?.data],
  )

  const allDifficulties = Object.entries(Difficulties)
    // exclude White difficulty
    .filter(([_, difficultyId]) => difficultyId !== '1')

  const allStatuses = Object.entries(StatusEnum)
    // exclude unknown
    .filter(([_, statusId]) => statusId !== -1)

  const onOpen = useCallback(() => {
    toggleOpen(true)
  }, [toggleOpen])

  const onClose = useCallback(() => {
    toggleOpen(false)
  }, [toggleOpen])

  const onFilterClear = useCallback(() => {
    trailSystemContext.setFilterParams((prev) => ({
      ...(prev.term && { term: prev.term }),
    }))
  }, [trailSystemContext])

  useEffect(() => {
    if (radius !== trailSystemContext.filterParams.radius) {
      setRadius(trailSystemContext.filterParams.radius || 0)
    }
  }, [trailSystemContext.filterParams.radius])

  const filterDrawerParamCount = useMemo(
    () =>
      trailSystemContext.filterParamsCount -
      // exclude term from filter count
      (trailSystemContext.filterParams.term?.length ? 1 : 0),
    [trailSystemContext.filterParamsCount, trailSystemContext.filterParams.term],
  )

  return (
    <>
      <Tooltip title="Explore search filters">
        <IconButton aria-label="filters" size="large" onClick={onOpen}>
          {filterDrawerParamCount > 0 && (
            <Stack
              alignItems="center"
              bgcolor="primary.main"
              borderRadius="50%"
              color="white"
              height={20}
              justifyContent="center"
              position="absolute"
              right={4}
              top={4}
              width={20}
            >
              <Typography fontSize={10} fontWeight="bold">
                {filterDrawerParamCount}
              </Typography>
            </Stack>
          )}
          <FilterAltIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <StyledDrawer open={open} anchor="right" onClose={onClose}>
        <Stack direction="row" alignItems="center" justifyContent="end" width="100%">
          <Button variant="text" color="secondary" onClick={onFilterClear}>
            Reset Filters
          </Button>
          <IconButton aria-label="close filters" size="large" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>

        <DrawerStack alignItems="stretch" spacing={2}>
          <Typography variant="h4" textAlign="center">
            Search Filters
          </Typography>

          {trailSystemContext.userLocApproved && (
            <FormControl fullWidth>
              <FilterSelect
                variant="outlined"
                displayEmpty
                value={radius}
                onChange={(e) => {
                  setRadius(e.target.value ? Number(e.target.value) : 0)
                  trailSystemContext.setFilterParams((prev) => ({
                    ...prev,
                    radius: e.target.value ? Number(e.target.value) : undefined,
                  }))
                }}
                input={<OutlinedInput aria-label="Distance" />}
              >
                <FilterMenuItem value={0}>Any Distance</FilterMenuItem>
                <FilterMenuItem value={5}>5 miles</FilterMenuItem>
                <FilterMenuItem value={10}>10 miles</FilterMenuItem>
                <FilterMenuItem value={25}>25 miles</FilterMenuItem>
                <FilterMenuItem value={50}>50 miles</FilterMenuItem>
                <FilterMenuItem value={100}>100 miles</FilterMenuItem>
              </FilterSelect>
            </FormControl>
          )}

          <FormControl fullWidth>
            <FilterSelect
              multiple
              variant="outlined"
              displayEmpty
              value={trailSystemContext.filterParams.featureIds || []}
              onChange={(e) => {
                const value = (
                  typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                ) as number[]
                trailSystemContext.setFilterParams((prev) => ({
                  ...prev,
                  featureIds:
                    !value?.length || value.length === allTrailFeatures.length || !value.every(Boolean)
                      ? undefined
                      : value,
                }))
              }}
              input={<OutlinedInput aria-label="Features" />}
              renderValue={(selected) => {
                if (!Array.isArray(selected) || !selected?.length || selected.length === allTrailFeatures.length) {
                  return <>All features</>
                }
                if (selected.length === 1) {
                  return <>{allTrailFeatures.find((trailFeature) => trailFeature.id === selected[0])?.name}</>
                }
                return <>{selected.length} features</>
              }}
            >
              <FilterMenuItem>All</FilterMenuItem>
              {allTrailFeatures.map((trailFeature) => (
                <FilterMenuItem key={trailFeature.id} value={trailFeature.id}>
                  {trailFeature.name}
                </FilterMenuItem>
              ))}
            </FilterSelect>
          </FormControl>

          <FormControl fullWidth>
            <FilterSelect
              multiple
              variant="outlined"
              displayEmpty
              value={trailSystemContext.filterParams.difficultyIds || []}
              onChange={(e) => {
                const value = (
                  typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                ) as Difficulties[]
                trailSystemContext.setFilterParams((prev) => ({
                  ...prev,
                  difficultyIds:
                    !value?.length || value.length === allDifficulties.length || !value.every(Boolean)
                      ? undefined
                      : value,
                }))
              }}
              input={<OutlinedInput aria-label="Difficulty" />}
              renderValue={(selected) => {
                if (!Array.isArray(selected) || !selected?.length || selected.length === allDifficulties.length) {
                  return <>All difficulties</>
                }
                if (selected.length === 1) {
                  return <>{allDifficulties.find((difficulty) => difficulty[1] === selected[0])?.[0]}</>
                }
                return <>{selected.length} difficulties</>
              }}
            >
              <FilterMenuItem>All</FilterMenuItem>
              {allDifficulties.map(([difficultyName, difficultyId]) => (
                <FilterMenuItem key={difficultyId} value={difficultyId}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    <Typography>{difficultyName}</Typography>
                    <DifficultyIcon difficultyId={difficultyId} fontSize={22} />
                  </Stack>
                </FilterMenuItem>
              ))}
            </FilterSelect>
          </FormControl>

          <FormControl fullWidth>
            <FilterSelect
              multiple
              variant="outlined"
              displayEmpty
              value={trailSystemContext.filterParams.statuses || []}
              onChange={(e) => {
                const value = (
                  typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                ) as Status[]
                trailSystemContext.setFilterParams((prev) => ({
                  ...prev,
                  statuses:
                    !value?.length ||
                    value.length === allStatuses.length ||
                    value.includes(undefined as unknown as Status)
                      ? undefined
                      : value,
                }))
              }}
              input={<OutlinedInput aria-label="Status" />}
              renderValue={(selected) => {
                if (!Array.isArray(selected) || !selected?.length || selected.length === allStatuses.length) {
                  return <>All statuses</>
                }
                if (selected.length === 1) {
                  return <>{allStatuses.find((status) => status[1] === selected[0])?.[0]}</>
                }
                return <>{selected.length} statuses</>
              }}
            >
              <FilterMenuItem>All</FilterMenuItem>
              {allStatuses.map(([statusName, statusId]) => (
                <FilterMenuItem key={statusId} value={statusId}>
                  {statusName}
                </FilterMenuItem>
              ))}
            </FilterSelect>
          </FormControl>

          <FormControl fullWidth>
            <FilterSelect
              multiple
              variant="outlined"
              displayEmpty
              value={trailSystemContext.filterParams.surfaceTypes || []}
              onChange={(e) => {
                const value = (
                  typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                ) as string[]
                trailSystemContext.setFilterParams((prev) => ({
                  ...prev,
                  surfaceTypes:
                    !value?.length || value.length === allTrailSurfaceTypes.length || !value.every(Boolean)
                      ? undefined
                      : value,
                }))
              }}
              input={<OutlinedInput aria-label="Surface Type" />}
              renderValue={(selected) => {
                if (!Array.isArray(selected) || !selected?.length || selected.length === allTrailSurfaceTypes.length) {
                  return <>All surfaces</>
                }
                if (selected.length === 1) {
                  return <>{selected[0]}</>
                }
                return <>{selected.length} surfaces</>
              }}
            >
              <FilterMenuItem>All</FilterMenuItem>
              {allTrailSurfaceTypes.map((surfaceType) => (
                <FilterMenuItem key={surfaceType} value={surfaceType}>
                  {surfaceType}
                </FilterMenuItem>
              ))}
            </FilterSelect>
          </FormControl>
          <Typography variant="subtitle1" textAlign="center">
            - OR -
          </Typography>
          <FilterButton variant="outlined" color="secondary" fullWidth onClick={onClose} href="/trail-finder">
            Use our trail finder
          </FilterButton>
        </DrawerStack>
      </StyledDrawer>
    </>
  )
}
TrailFilterDrawer.displayName = 'TrailFilterDrawer'
