import CircleIcon from '@mui/icons-material/Circle'
import SquareIcon from '@mui/icons-material/Square'
import { Box, Stack } from '@mui/material'

import { FC } from 'react'

import { Difficulties, DifficultyColors } from '../../models'

type DifficultyIconProps = {
  difficultyId: Difficulties
  fontSize?: number
}

export const DifficultyIcon: FC<DifficultyIconProps> = (props) => {
  return (
    <Box fontSize={props.fontSize ?? 18}>
      {props.difficultyId === '1' && (
        <CircleIcon fontSize="inherit" sx={{ color: DifficultyColors[props.difficultyId] }} />
      )}
      {props.difficultyId === '2' && (
        <CircleIcon fontSize="inherit" sx={{ color: DifficultyColors[props.difficultyId] }} />
      )}
      {props.difficultyId === '3' && (
        <SquareIcon fontSize="inherit" sx={{ color: DifficultyColors[props.difficultyId] }} />
      )}
      {props.difficultyId === '4' && (
        <SquareIcon
          fontSize="inherit"
          sx={{ color: DifficultyColors[props.difficultyId], transform: 'rotate(45deg)' }}
        />
      )}
      {props.difficultyId === '5' && (
        <Stack marginTop="-0.25em" marginBottom="-0.25em">
          <SquareIcon
            fontSize="inherit"
            sx={{
              color: DifficultyColors[props.difficultyId],
              mb: '-0.5em',
              transform: 'rotate(45deg) scale(0.75)',
            }}
          />
          <SquareIcon
            fontSize="inherit"
            sx={{ color: DifficultyColors[props.difficultyId], transform: 'rotate(45deg) scale(0.75)' }}
          />
        </Stack>
      )}
    </Box>
  )
}
DifficultyIcon.displayName = 'DifficultyIcon'
