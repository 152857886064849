import { Box, BoxProps, Modal, Stack, styled, Typography } from '@mui/material'

import { useEffect, useState } from 'react'

const AppUpdateModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  background: theme.palette.gradient.main,
  boxShadow: theme.shadows[5],
  color: theme.palette.gradient.contrastText,
  left: '50%',
  maxWidth: '100%',
  padding: theme.spacing(4),
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
}))

export const AppUpdatePrompter = () => {
  const [countdown, setCountdown] = useState(20)

  useEffect(() => {
    // setup a countdown timer to show on the update prompt. After 20 seconds, automatically refresh the page
    const newReloadInterval = setInterval(() => {
      setCountdown((prev) => {
        const newCountdown = prev - 1
        if (newCountdown <= 0) {
          window.location.reload()
        }
        return newCountdown
      })
    }, 1000)
    // clear out the interval when unmounting the component
    return () => clearInterval(newReloadInterval)
  }, [])

  return (
    <Modal open>
      <AppUpdateModalContent>
        <Stack justifyContent="center" spacing={2}>
          <Typography variant="h1" fontSize={28}>
            App Update Required
          </Typography>
          <Typography>Please refresh your app to fetch the latest version.</Typography>
          <Typography>This page will auto-refresh in {countdown} seconds.</Typography>
        </Stack>
      </AppUpdateModalContent>
    </Modal>
  )
}
