import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const FlowfeedLogoIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={4}
      strokeMiterlimit={10}
      d="M129.1,20.1L129.1,20.1l-4.6-3.9l-3.6,4.2l-0.3,0.4L17.5,142.6L77.8,118l42.7,34.2l75.7-89.9l26.4,22.4h9.3l-31.9-27l27.8-33l70.5,59.5h9.3l-80.5-68l-32.9,39l-2.7,3.2l0,0l-7.3,8.6l-3.9,4.6l-60.8,72.2l-40.9-32.7l-41,16.7l55.5-65.6L120,84.7h9.3L97.3,57.7l27.6-32.6l0.3-0.4L129.1,20.1z"
    />
    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1={191.9943} y1={47.6311} x2={130.9809} y2={41.0455}>
      <stop offset={8.833618e-2} stopColor="#FFFFFF" stopOpacity={0} />
      <stop offset={0.325} stopColor="#FFFFFF" stopOpacity={0.502} />
      <stop offset={0.8895} stopColor="#FFFFFF" />
    </linearGradient>
    <path fill="url(#SVGID_1_)" d="M179.2,73.1l-55.4-47l-5.6-5.5l6.1-7.2L132,20l0,0l53.6,45.5L179.2,73.1z" />
  </g>,
  'FlowfeedLogoIcon',
)
FlowfeedLogoIcon.defaultProps = {
  sx: {
    height: '0.6em',
  },
  viewBox: '0 0 325 167.1',
}
FlowfeedLogoIcon.displayName = 'FlowfeedLogoIcon'
