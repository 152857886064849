import { Box, BoxProps, Stack, styled, Typography, useTheme } from '@mui/material'

import { FC, useMemo } from 'react'

import { StatusDescriptions } from '../../models'
import { TrailSystemStatusRing } from '../index'

const RingsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'mini',
})<BoxProps & { mini?: boolean }>(({ theme, mini }) => ({
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.down('tiny')]: {
    transform: mini ? undefined : 'scale(0.8)',
  },
}))

const RingsLabel = styled(Stack)(() => ({
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
}))

const FirmRing = styled(Box)(() => ({
  position: 'relative',
}))

const AbsoluteRing = styled(Box)(() => ({
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
}))

type TrailSystemStatusProps = {
  values: {
    firm: number
    fair: number
    rutty: number
    closed: number
  }
  count: number
  mini?: boolean
}

export const TrailSystemStatus: FC<TrailSystemStatusProps> = (props) => {
  const theme = useTheme()

  const max = useMemo(() => {
    return Math.max(props.values.firm, props.values.fair, props.values.rutty, props.values.closed)
  }, [props.values])

  return (
    <RingsContainer mini={props.mini}>
      <RingsLabel alignItems="center">
        <Typography variant="h3" fontWeight={700} fontSize={props.mini ? 32 : 48} lineHeight={1}>
          {props.count}
        </Typography>
        <Typography variant="body2" fontSize={props.mini ? 12 : 14}>
          trails
        </Typography>
      </RingsLabel>
      <AbsoluteRing>
        <TrailSystemStatusRing
          value={props.values.closed}
          label="Closed"
          size={140}
          scale={props.mini ? 0.75 : 1}
          total={props.count}
          color={theme.palette.grey[600]}
          max={max}
          tooltip={StatusDescriptions[0]}
        />
      </AbsoluteRing>
      <AbsoluteRing>
        <TrailSystemStatusRing
          value={props.values.rutty}
          label="Rutty"
          size={180}
          scale={props.mini ? 0.75 : 1}
          total={props.count}
          color={theme.palette.trailStatus.dark}
          tooltip={StatusDescriptions[1]}
          max={max}
        />
      </AbsoluteRing>
      <AbsoluteRing>
        <TrailSystemStatusRing
          value={props.values.fair}
          label="Fair"
          size={220}
          scale={props.mini ? 0.75 : 1}
          total={props.count}
          color={theme.palette.trailStatus.main}
          tooltip={StatusDescriptions[2]}
          max={max}
        />
      </AbsoluteRing>
      <FirmRing>
        <TrailSystemStatusRing
          value={props.values.firm}
          label="Firm"
          size={260}
          scale={props.mini ? 0.75 : 1}
          total={props.count}
          color={theme.palette.trailStatus.light}
          tooltip={StatusDescriptions[3]}
          max={max}
        />
      </FirmRing>
    </RingsContainer>
  )
}
TrailSystemStatus.displayName = 'TrailSystemStatus'
