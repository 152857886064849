import { alpha, Box, BoxProps, Container, Stack, styled, Typography } from '@mui/material'

import { FC, useEffect, useRef, useState } from 'react'

const StyledFooterBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSticky',
})<
  BoxProps & {
    isSticky?: boolean
  }
>(({ theme, isSticky }) => ({
  bottom: -1,
  ...(isSticky && {
    background: alpha('#000', 0.75),
    boxShadow: theme.shadows[4],
  }),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
  position: 'sticky',
  width: '100%',
  zIndex: 1000,
}))

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

const ExtraDescriptor = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const minuteToRefetch = 21

const calculateMinutesSinceLastUpdate = () => {
  const thisMinute = new Date().getMinutes()
  return thisMinute >= minuteToRefetch ? thisMinute - minuteToRefetch : 60 - minuteToRefetch + thisMinute
}

const calculateMinutesUntilNextUpdate = () => {
  const thisMinute = new Date().getMinutes()
  return thisMinute >= minuteToRefetch ? 60 - thisMinute + minuteToRefetch : minuteToRefetch - thisMinute
}

export const TrailDataFooter: FC = () => {
  const footerContainerRef = useRef<HTMLDivElement>(null)
  const [isSticky, setIsSticky] = useState(false)
  const [minutesSinceLastUpdate, setMinutesSinceLastUpdate] = useState(calculateMinutesSinceLastUpdate())
  const [minutesUntilNextUpdate, setMinutesUntilNextUpdate] = useState(calculateMinutesUntilNextUpdate())

  setInterval(() => {
    setMinutesSinceLastUpdate(calculateMinutesSinceLastUpdate())
    setMinutesUntilNextUpdate(calculateMinutesUntilNextUpdate())
  }, 60 * 1000) // 1 minute

  const observer = new IntersectionObserver(
    ([e]) => {
      setIsSticky(e.intersectionRatio < 1)
    },
    {
      threshold: [1],
    },
  )
  useEffect(() => {
    if (footerContainerRef.current) {
      observer.observe(footerContainerRef.current)
    }
    return () => {
      if (footerContainerRef.current) {
        observer.unobserve(footerContainerRef.current)
      }
    }
  }, [footerContainerRef.current])

  return (
    <>
      <StyledFooterBox ref={footerContainerRef} isSticky={isSticky}>
        <StyledContainer maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack alignItems="center">
              <Typography variant="body1" color="grey" fontSize={{ sm: 16, xs: 12 }}>
                {minutesSinceLastUpdate} mins
              </Typography>
              <Typography variant="caption" color="grey">
                <ExtraDescriptor>since&nbsp;</ExtraDescriptor>last update
              </Typography>
            </Stack>
            {!!process.env.REACT_APP_NOTIFICATION && (
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_NOTIFICATION }}
              ></Stack>
            )}
            <Stack alignItems="center">
              <Typography variant="body1" fontSize={{ sm: 16, xs: 12 }}>
                {minutesUntilNextUpdate} mins
              </Typography>
              <Typography variant="caption">
                <ExtraDescriptor>until&nbsp;</ExtraDescriptor>next update
              </Typography>
            </Stack>
          </Stack>
        </StyledContainer>
      </StyledFooterBox>
    </>
  )
}
TrailDataFooter.displayName = 'TrailDataFooter'
