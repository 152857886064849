import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const SnowIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <g id="SNOW">
      <g>
        <line
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.25}
          strokeLinecap="round"
          strokeMiterlimit={10}
          x1="32"
          y1="2.2"
          x2="32"
          y2="61.8"
        />
        <polyline
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.25}
          strokeLinecap="round"
          strokeMiterlimit={10}
          points="39.7,7 32,14.6 32,14.6 24.3,7"
        />
        <polyline
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.25}
          strokeLinecap="round"
          strokeMiterlimit={10}
          points="39.7,57 32,49.4 32,49.4 24.3,57"
        />
      </g>
      <g>
        <line
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.25}
          strokeLinecap="round"
          strokeMiterlimit={10}
          x1="6.2"
          y1="17.1"
          x2="57.8"
          y2="46.9"
        />
        <polyline
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.25}
          strokeLinecap="round"
          strokeMiterlimit={10}
          points="14.2,12.9 17,23.3 17,23.3 6.5,26.1"
        />
        <polyline
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.25}
          strokeLinecap="round"
          strokeMiterlimit={10}
          points="57.5,37.9 47.1,40.7 47.1,40.7 49.9,51.2"
        />
      </g>
      <g>
        <line
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.25}
          strokeLinecap="round"
          strokeMiterlimit={10}
          x1="6.2"
          y1="46.9"
          x2="57.8"
          y2="17.1"
        />
        <polyline
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.25}
          strokeLinecap="round"
          strokeMiterlimit={10}
          points="6.5,37.9 16.9,40.7 16.9,40.7 14.1,51.2"
        />
        <polyline
          fill="none"
          stroke="#F0F0F0"
          strokeWidth={2.25}
          strokeLinecap="round"
          strokeMiterlimit={10}
          points="49.8,12.9 47,23.3 47,23.3 57.5,26.1"
        />
      </g>
    </g>
  </g>,
  'SnowIcon',
)
SnowIcon.defaultProps = {
  viewBox: '0 0 64 64',
}
SnowIcon.displayName = 'SnowIcon'
