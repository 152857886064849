import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const ThunderstormIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <g>
      <path
        fill="#F0F0F0"
        d="M10.6,22.9c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0-9.7,7.9-17.6,17.6-17.6c6.9,0,12.8,4,15.7,9.7	c1.5-0.6,3.1-0.9,4.8-0.9c7.3,0,13.2,5.9,13.2,13.2S56.3,40.5,49,40.5l0,0H10.6c-4.9,0-8.8-3.9-8.8-8.8S5.7,22.9,10.6,22.9z"
      />
    </g>
    <g>
      <path fill="#F0F0F0" d="M45,50.9c0,1.7-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1c0-1.7,3.1-6.5,3.1-6.5S45,49.2,45,50.9z" />
      <path
        fill="#F0F0F0"
        d="M35.4,55.7c0,1.7-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1c0-1.7,3.1-6.5,3.1-6.5S35.4,54,35.4,55.7z"
      />
      <polygon fill="#FF7900" points="28.7,35.3 23.7,35.3 26.5,22.3 14.1,38.3 19,38.3 16.3,51.3" />
    </g>
  </g>,
  'ThunderstormIcon',
)
ThunderstormIcon.defaultProps = {
  viewBox: '0 0 64 64',
}
ThunderstormIcon.displayName = 'ThunderstormIcon'
