import { useEffect, useState } from 'react'
import { useToggle } from 'react-use'

import { UserLocationService } from '../services'

export const useUserLocation = () => {
  const userLocationService = new UserLocationService()

  const [userLocApproved, toggleUserLocApproved] = useToggle(true)
  const [userCoords, setUserCoords] = useState<{ lat: number; long: number }>()
  const [userLocality, setUserLocality] = useState<string>()

  const requestUserCoords = () =>
    new Promise<{ lat: number; long: number }>((resolve, reject) => {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({ lat: position.coords.latitude, long: position.coords.longitude })
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              toggleUserLocApproved(false)
            }
            reject(error.message)
          },
        )
      } else {
        reject('Browser does not support geoLocation data.')
      }
    }).then((coords) => {
      setUserCoords(coords)
      return coords
    })

  const requestGoogleGeoLocation = () => {
    if (userCoords) {
      userLocationService.requestGoogleGeoLocation(userCoords).then((locality) => {
        setUserLocality(locality)
      })
    }
  }

  useEffect(() => {
    requestGoogleGeoLocation()
  }, [userCoords])

  return {
    requestGoogleGeoLocation,
    requestUserCoords,
    userCoords,
    userLocApproved,
    userLocality,
  }
}
