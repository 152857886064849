import { FC, useState } from 'react'

import { AppUpdatePrompter } from '../components'

type AppUpdateContextType = {
  triggerAppUpdate: () => void
}

export const AppUpdateContext: AppUpdateContextType = {
  triggerAppUpdate: () => {
    // if used outside of AppUpdateProvider, this function will not have been replaced so throw error
    throw new Error('AppUpdateContext must be used within a AppUpdateProvider')
  },
}

type AppUpdateProviderProps = { children: React.ReactNode }

export const AppUpdateProvider: FC<AppUpdateProviderProps> = (props) => {
  const [needsUpdate, setNeedsUpdate] = useState(false)

  AppUpdateContext.triggerAppUpdate = () => {
    setNeedsUpdate(true)
  }

  return (
    <>
      {needsUpdate && <AppUpdatePrompter />}
      {props.children}
    </>
  )
}
