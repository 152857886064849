import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const OvercastCloudsIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <g id="CLOUDS">
      <path
        fill="#F0F0F0"
        d="M9.8,32c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0-10.1,8.2-18.2,18.2-18.2c7.1,0,13.3,4.1,16.3,10.1	c1.5-0.6,3.2-1,5-1c7.6,0,13.7,6.1,13.7,13.7s-6.1,13.7-13.7,13.7l0,0H9.8c-5,0-9.1-4.1-9.1-9.1S4.8,32,9.8,32z"
      />
    </g>
  </g>,
  'OvercastCloudsIcon',
)
OvercastCloudsIcon.defaultProps = {
  viewBox: '0 0 64 64',
}
OvercastCloudsIcon.displayName = 'OvercastCloudsIcon'
