import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const ClearSkyDayIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <circle fill="#FFDC64" cx="32" cy="32" r="30" />
  </g>,
  'ClearSkyDayIcon',
)
ClearSkyDayIcon.defaultProps = {
  viewBox: '0 0 64 64',
}
ClearSkyDayIcon.displayName = 'ClearSkyDayIcon'
