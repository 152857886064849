import CloseIcon from '@mui/icons-material/Close'
import NearMeIcon from '@mui/icons-material/NearMe'
import NearMeDisabledIcon from '@mui/icons-material/NearMeDisabled'
import { alpha, Box, IconButton, Stack, StackProps, styled, Tooltip, Typography } from '@mui/material'

import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { TrailFilterDrawer, TrailFinderIcon, TrailSearch } from '../'
import { Difficulties, StatusEnum, TrailFeature } from '../../models'
import { conjunctArray } from '../../utils'
import { TrailSystemContext } from './../../providers'

const FiltersContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isSticky',
})<
  StackProps & {
    isSticky?: boolean
  }
>(({ theme, isSticky }) => ({
  padding: theme.spacing(1, 0),
  ...(isSticky && {
    background: alpha('#000', 0.75),
    boxShadow: theme.shadows[4],
  }),
  zIndex: theme.zIndex.appBar,
}))

const ClearButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[500],
}))

type TrailFilterBarProps = {
  trailSystemId?: number
}

export const TrailFilterBar: FC<TrailFilterBarProps> = (props) => {
  const filtersContainerRef = useRef<HTMLDivElement>(null)
  const trailSystemContext = useContext(TrailSystemContext)
  const [isSticky, setIsSticky] = useState(false)

  const allTrailFeatures = useMemo(
    () =>
      Object.values(
        (trailSystemContext.query?.data || [])
          .flatMap((ts) => ts.trails)
          .flatMap((t) => t?.features)
          .filter(Boolean)
          .reduce(
            (acc, f) => ({
              ...acc,
              ...(f?.id && { [f.id]: f }),
            }),
            {} as Record<number, TrailFeature>,
          ),
      ),
    [trailSystemContext.query?.data],
  )

  const observer = new IntersectionObserver(
    ([e]) => {
      setIsSticky(e.intersectionRatio < 1)
    },
    {
      threshold: [1],
    },
  )
  useEffect(() => {
    if (filtersContainerRef.current) {
      observer.observe(filtersContainerRef.current)
    }
    return () => {
      if (filtersContainerRef.current) {
        observer.unobserve(filtersContainerRef.current)
      }
    }
  }, [filtersContainerRef.current])

  const onSearchClear = () => {
    trailSystemContext.setFilterParams({})
  }

  const onNearMe = useCallback(() => {
    trailSystemContext.setFilterParams((prev) => ({
      ...prev,
      radius: 10,
    }))
  }, [trailSystemContext.setFilterParams])

  const filterTrailFeatureNames = useMemo(
    () =>
      allTrailFeatures
        .filter((trailFeature) => trailSystemContext.filterParams.featureIds?.includes(trailFeature.id))
        .map((trailFeature) => trailFeature.name),
    [trailSystemContext.filterParams.featureIds],
  )

  const filterDifficultyNames = useMemo(
    () =>
      Object.entries(Difficulties)
        .filter(([_, difficultyId]) => trailSystemContext.filterParams.difficultyIds?.includes(difficultyId))
        .map(([difficultyName, _]) => difficultyName),
    [trailSystemContext.filterParams.difficultyIds],
  )

  const filterStatusNames = useMemo(
    () =>
      Object.entries(StatusEnum)
        .filter(([_, statusId]) => trailSystemContext.filterParams.statuses?.includes(statusId))
        .map(([statusName, _]) => statusName),
    [trailSystemContext.filterParams.statuses],
  )

  return (
    <Box display="contents">
      <FiltersContainer
        ref={filtersContainerRef}
        direction="row"
        justifyContent="space-between"
        position="sticky"
        top={-1}
        isSticky={isSticky}
      >
        <TrailSearch trailSystemId={props.trailSystemId} />
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton aria-label="Trail Finder" size="large" href="/trail-finder">
            <Stack
              alignItems="center"
              bgcolor="primary.main"
              borderRadius="50%"
              color="white"
              height={9}
              justifyContent="center"
              position="absolute"
              right={8}
              top={10}
              width={9}
            ></Stack>
            <TrailFinderIcon sx={{ fontSize: 22 }} />
          </IconButton>
          <Tooltip
            title={
              trailSystemContext.userLocApproved
                ? 'Search for trails near me'
                : 'Geolocation Disabled: Please enable location access in your browser settings.'
            }
          >
            <span>
              <IconButton
                aria-label="Near Me"
                size="large"
                onClick={onNearMe}
                disabled={!trailSystemContext.userLocApproved}
              >
                {trailSystemContext.userLocApproved ? (
                  <NearMeIcon sx={{ fontSize: 22 }} />
                ) : (
                  <NearMeDisabledIcon sx={{ fontSize: 22 }} />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <TrailFilterDrawer />
        </Stack>
      </FiltersContainer>
      {trailSystemContext.filterParamsCount > 0 && (
        <Stack direction="row" alignItems="center" paddingBottom={1}>
          <ClearButton aria-label="clear" className="search-clear-icon" onClick={onSearchClear}>
            <CloseIcon />
          </ClearButton>
          <Typography variant="body2">
            Showing results
            {trailSystemContext.filterParams.term?.length ? ` matching "${trailSystemContext.filterParams.term}"` : ''}
            {filterTrailFeatureNames?.length ? ` with ${conjunctArray(filterTrailFeatureNames, 'and')}` : ''}
            {filterDifficultyNames?.length ? ` that are ${conjunctArray(filterDifficultyNames, 'or')}` : ''}
            {filterStatusNames?.length ? ` that are ${conjunctArray(filterStatusNames, 'or')}` : ''}
            {trailSystemContext.filterParams.surfaceTypes?.length
              ? ` with a surface of ${conjunctArray(trailSystemContext.filterParams.surfaceTypes, 'or')}`
              : ''}
            {trailSystemContext.filterParams.radius
              ? ` within ${trailSystemContext.filterParams.radius} miles${
                  trailSystemContext.userLocality ? ` of ${trailSystemContext.userLocality}` : ''
                }`
              : ''}
          </Typography>
        </Stack>
      )}
    </Box>
  )
}
TrailFilterBar.displayName = 'TrailFilterBar'
