import AddIcon from '@mui/icons-material/Add'
import AirIcon from '@mui/icons-material/Air'
import CloseIcon from '@mui/icons-material/Close'
import DryIcon from '@mui/icons-material/Dry'
import FlareIcon from '@mui/icons-material/Flare'
import NightsStayIcon from '@mui/icons-material/NightsStay'
import WaterDropIcon from '@mui/icons-material/WaterDrop'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Modal,
  Stack,
  StackProps,
  styled,
  Typography,
} from '@mui/material'

import { FC, useMemo } from 'react'
import { useToggle } from 'react-use'

import { weatherConditions, WeatherRecord } from '../../models'
import {
  calcDayNightCycle,
  convertCelsiusToFahrenheit,
  convertDegreesToDirection,
  convertMetersPerSecToMilesPerHour,
} from './../../utils'
import { WeatherIcon } from './../icons'

const MoreButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '& .MuiButton-endIcon': {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.25),
  },
  color: theme.palette.grey[500],
  textTransform: 'none',
}))

const MoreModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  background: theme.palette.gradientLight.main,
  left: '50%',
  maxHeight: '95%',
  maxWidth: 500,
  overflowY: 'auto',
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(6),
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
}))

const CloseButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}))

const OutlinedRow = styled(Stack)<StackProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[500]}`,
  maxWidth: 375,
  width: '100%',
}))

const FirstCell = styled(Stack)<StackProps>(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    width: '100%',
  },
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  width: '50%',
}))

const SecondCell = styled(Stack)<StackProps>(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

type WeatherDisplayMoreProps = {
  weather: WeatherRecord
}

export const WeatherDisplayMore: FC<WeatherDisplayMoreProps> = (props) => {
  const [open, toggleOpen] = useToggle(false)
  const condition = useMemo(() => {
    return weatherConditions.find((weatherCondition) => weatherCondition.id === props.weather.conditionId)
  }, [props.weather.conditionId, weatherConditions])

  return (
    <>
      <MoreButton variant="text" color="secondary" size="small" endIcon={<AddIcon />} onClick={() => toggleOpen(true)}>
        More
      </MoreButton>
      {condition && (
        <Modal open={open} onClose={() => toggleOpen(false)}>
          <MoreModalContent>
            <CloseButton color="secondary" size="small" onClick={() => toggleOpen(false)}>
              <CloseIcon />
            </CloseButton>
            <Stack justifyContent="center" alignItems="center" width="100%" spacing={2}>
              <Typography component="h2" variant="h6" width="100%">
                {new Date().toLocaleString('en-US', { day: 'numeric', weekday: 'short' })} |{' '}
                {calcDayNightCycle(props.weather.sunrise, props.weather.sunset)}
              </Typography>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Stack fontSize={80}>
                  <WeatherIcon
                    fontSize="inherit"
                    conditionId={props.weather.conditionId}
                    cycle={calcDayNightCycle(props.weather.sunrise, props.weather.sunset)}
                  />
                </Stack>
                <Stack alignItems="center">
                  <Typography variant="h3" fontSize={56} pl={2}>
                    {Math.round(convertCelsiusToFahrenheit(props.weather.temperature))}&deg;
                  </Typography>
                  <Typography variant="caption">{condition.description}</Typography>
                </Stack>
              </Stack>

              <Typography component="p" variant="h6">
                High/Low {Math.round(convertCelsiusToFahrenheit(props.weather.tempMax))}/
                {Math.round(convertCelsiusToFahrenheit(props.weather.tempMin))}&deg;
              </Typography>

              <Stack alignItems="center" width="100%">
                <OutlinedRow direction={{ sm: 'row' }} justifyContent="space-between" alignItems={{ sm: 'center' }}>
                  <FirstCell alignItems="center" justifyContent="center" spacing={0.5}>
                    <Typography component="h3" variant="h6">
                      Wind
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <AirIcon />
                      <Typography>
                        {!props.weather.windSpeed
                          ? 'None'
                          : `${convertDegreesToDirection(props.weather.windDirection)} ${Math.round(convertMetersPerSecToMilesPerHour(props.weather.windSpeed))} mph`}
                      </Typography>
                    </Stack>
                  </FirstCell>
                  <SecondCell alignItems="center" justifyContent="center" spacing={0.5}>
                    <Typography component="h3" variant="h6">
                      UV Index
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <FlareIcon />
                      <Typography>{props.weather.uv} of 10</Typography>
                    </Stack>
                  </SecondCell>
                </OutlinedRow>

                <OutlinedRow direction={{ sm: 'row' }} justifyContent="space-between" alignItems={{ sm: 'center' }}>
                  <FirstCell alignItems="center" justifyContent="center" spacing={0.5}>
                    <Typography component="h3" variant="h6">
                      Humidity
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <WaterDropIcon />
                      <Typography>{props.weather.relativeHumidity}%</Typography>
                    </Stack>
                  </FirstCell>
                  <SecondCell alignItems="center" justifyContent="center" spacing={0.5}>
                    <Typography component="h3" variant="h6">
                      Feels Like
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <DryIcon />
                      <Typography>{Math.round(convertCelsiusToFahrenheit(props.weather.feelsLike))}&deg;</Typography>
                    </Stack>
                  </SecondCell>
                </OutlinedRow>

                <OutlinedRow direction={{ sm: 'row' }} justifyContent="space-between" alignItems={{ sm: 'center' }}>
                  <FirstCell alignItems="center" justifyContent="center" spacing={0.5}>
                    <Typography component="h3" variant="h6">
                      Sunrise
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <WbSunnyIcon />
                      <Typography>
                        {!props.weather.sunrise
                          ? '-'
                          : new Date(props.weather.sunrise).toLocaleString('en-US', {
                              hour12: true,
                              timeStyle: 'short',
                            })}
                      </Typography>
                    </Stack>
                  </FirstCell>
                  <SecondCell alignItems="center" justifyContent="center" spacing={0.5}>
                    <Typography component="h3" variant="h6">
                      Sunset
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <NightsStayIcon />
                      <Typography>
                        {!props.weather.sunset
                          ? '-'
                          : new Date(props.weather.sunset).toLocaleString('en-US', {
                              hour12: true,
                              timeStyle: 'short',
                            })}
                      </Typography>
                    </Stack>
                  </SecondCell>
                </OutlinedRow>
              </Stack>
            </Stack>
          </MoreModalContent>
        </Modal>
      )}
    </>
  )
}
WeatherDisplayMore.displayName = 'WeatherDisplayMore'
