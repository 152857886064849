import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import {
  alpha,
  Box,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  IconProps,
  InputBase,
  InputBaseProps,
  Stack,
  StackProps,
  styled,
} from '@mui/material'

import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { AutocompleteTrailSearchDropdown } from '..'
import { TrailSystemContext } from './../../providers'

const MobileSearchButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  borderRadius: 999,
  justifyContent: 'start',
  width: '100%',
}))

const SearchContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<StackProps & { isOpen: boolean }>(({ theme, isOpen }) => ({
  [theme.breakpoints.down('sm')]: {
    background: alpha('#000', 0.75),
    bottom: 0,
    display: isOpen ? undefined : 'none',
    left: 0,
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(2),
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: theme.zIndex.appBar + 1,
  },
  width: '100%',
}))

const MobileSearchBackdrop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  height: '100%',
  position: 'fixed',
  width: '100%',
  zIndex: -1,
}))

const SearchInput = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  '& .MuiInputBase-input': {
    height: theme.spacing(4),
    paddingLeft: theme.spacing(1),
  },
  '& .search-clear-icon, & .search-submit-button': {
    visibility: 'hidden',
  },
  '&.Mui-focused': {
    backgroundColor: theme.palette.grey[800],
  },
  '&.Mui-focused .search-clear-icon, &.Mui-focused .search-submit-button': {
    visibility: 'visible',
  },
  '&:hover .search-clear-icon, &:hover .search-submit-button': {
    visibility: 'visible',
  },
  '&:not(.Mui-focused):hover': {
    backgroundColor: theme.palette.grey[800],
    opacity: 0.7,
  },
  borderRadius: theme.spacing(5),
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    borderRadius: theme.spacing(1),
  },
}))

const StyledSearchIcon = styled(SearchIcon)<IconProps>(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
  },
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}))

const StyledSearchClearButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.grey[600],
}))

const StyledSearchSubmitButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: theme.spacing(2),
  color: theme.palette.grey[300],
  textTransform: 'none',
}))

type TrailSearchProps = {
  trailSystemId?: number
}

export const TrailSearch: FC<TrailSearchProps> = (props) => {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const trailSystemContext = useContext(TrailSystemContext)

  useEffect(() => {
    // ensure external updates to search params are reflected in the input
    if (trailSystemContext.filterParams.term !== searchValue) {
      setSearchValue(trailSystemContext.filterParams.term ?? '')
    }
  }, [trailSystemContext.filterParams.term])

  const handleSearchOpen = () => {
    setIsOpen(true)
    setTimeout(() => searchInputRef.current?.focus(), 0)
  }

  const handleSearchClose = () => {
    setIsOpen(false)
    searchInputRef.current?.blur()
  }

  const handleSearchFocus = useCallback(() => {
    searchInputRef.current?.focus()
  }, [searchInputRef])

  const handleSearchClear = () => {
    setSearchValue('')
    trailSystemContext.setFilterParams((prev) => ({ ...prev, term: '' }))
    handleSearchClose()
  }

  const handleSearch = () => {
    trailSystemContext.setFilterParams((prev) => ({ ...prev, term: searchValue }))
    handleSearchClose()
  }

  return (
    <Stack direction="row" alignItems="center" width="100%">
      <MobileSearchButton aria-label="Search" size="large" onClick={handleSearchOpen}>
        <StyledSearchIcon />
      </MobileSearchButton>
      <SearchContainer isOpen={isOpen}>
        <MobileSearchBackdrop onClick={handleSearchClose} />
        <Stack position="relative" width="100%">
          <SearchInput
            inputRef={searchInputRef}
            startAdornment={<StyledSearchIcon onClick={handleSearchFocus} />}
            endAdornment={
              <Stack direction="row" alignItems="center">
                {!!searchValue && (
                  <StyledSearchClearButton
                    aria-label="clear"
                    color="secondary"
                    className="search-clear-icon"
                    onClick={handleSearchClear}
                  >
                    <CloseIcon />
                  </StyledSearchClearButton>
                )}
                <StyledSearchSubmitButton
                  variant="text"
                  color="secondary"
                  className="search-submit-button"
                  onClick={handleSearch}
                >
                  Go
                </StyledSearchSubmitButton>
              </Stack>
            }
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch()
              }
              if (e.key === 'Escape') {
                handleSearchClose()
              }
            }}
          />
          <AutocompleteTrailSearchDropdown
            queryString={searchValue}
            trailSystemId={props.trailSystemId}
            searchInputRef={searchInputRef}
            onClick={handleSearchClose}
          />
        </Stack>
      </SearchContainer>
    </Stack>
  )
}
TrailSearch.displayName = 'TrailSearch'
