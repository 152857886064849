import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const TornadoIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <g id="CLOUDS">
      <path
        fill="#F0F0F0"
        d="M17.6,14.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0-7.4,6-13.4,13.4-13.4c5.3,0,9.8,3,12,7.4	c1.1-0.4,2.4-0.7,3.7-0.7C52.5,7.5,57,12,57,17.5s-4.5,10.1-10.1,10.1l0,0H17.6c-3.7,0-6.7-3-6.7-6.7S13.9,14.2,17.6,14.2z"
      />
    </g>
    <g id="TORNADO">
      <line
        fill="none"
        stroke="#F0F0F0"
        strokeWidth={2.4349}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="12.1"
        y1="33.9"
        x2="47"
        y2="33.9"
      />
      <line
        fill="none"
        stroke="#F0F0F0"
        strokeWidth={2.4349}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="11.4"
        y1="39.5"
        x2="40.5"
        y2="39.5"
      />
      <line
        fill="none"
        stroke="#F0F0F0"
        strokeWidth={2.4349}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="14.8"
        y1="45.1"
        x2="34.5"
        y2="45.1"
      />
      <line
        fill="none"
        stroke="#F0F0F0"
        strokeWidth={2.4349}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="22.1"
        y1="50.8"
        x2="36.1"
        y2="50.8"
      />
      <line
        fill="none"
        stroke="#F0F0F0"
        strokeWidth={2.4349}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="25.7"
        y1="56.4"
        x2="34.5"
        y2="56.4"
      />
      <line
        fill="none"
        stroke="#F0F0F0"
        strokeWidth={2.4349}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="30.3"
        y1="62"
        x2="33"
        y2="62"
      />
    </g>
    <g id="LIGHTNING">
      <polygon fill="#FF7900" points="31.4,23.7 27.6,23.7 29.7,13.8 20.3,25.9 24,25.9 21.9,35.9" />
    </g>
  </g>,
  'TornadoIcon',
)
TornadoIcon.defaultProps = {
  viewBox: '0 0 64 64',
}
TornadoIcon.displayName = 'TornadoIcon'
