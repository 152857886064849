import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, Link, Stack, styled } from '@mui/material'

import { FC, useContext, useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import { FlowfeedLogoIcon, FlowfeedLogoText, TrailSystemCard } from '../components'
import { TrailSystemContext } from '../providers'

const FlowfeedLogoHolder = styled(Box)(({ theme }) => ({
  left: 0,
  position: 'fixed',
  top: 0,
  zIndex: theme.zIndex.appBar,
}))

const ScrollContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  padding: theme.spacing(0, 1),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(6),
  [theme.breakpoints.down('tiny')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  position: 'relative',
}))

const TrailSystemCardWrapper = styled(Box)({
  flexShrink: 0,
  maxWidth: 240,
  width: 240,
})

const EmbeddedTrailSystemsContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(-3),
  overflowX: 'auto',
  padding: theme.spacing(0, 1),
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  whiteSpace: 'nowrap',
  width: '100%',
}))

const ScrollLeftContainer = styled(Stack)(({ theme }) => ({
  bottom: 0,
  cursor: 'pointer',
  fontSize: 32,
  left: 0,
  paddingLeft: theme.spacing(1),
  position: 'absolute',
  top: 0,
  zIndex: 2,
  [theme.breakpoints.down('tiny')]: {
    fontSize: 14,
    paddingLeft: theme.spacing(0.5),
  },
}))

const ScrollRightContainer = styled(Stack)(({ theme }) => ({
  bottom: 0,
  cursor: 'pointer',
  fontSize: 32,
  paddingRight: theme.spacing(1),
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 2,
  [theme.breakpoints.down('tiny')]: {
    fontSize: 14,
    paddingRight: theme.spacing(0.5),
  },
}))

export const EmbeddedTrailSystems: FC = () => {
  const trailSystemContainerRef = useRef<HTMLDivElement>(null)
  const trailSystemContext = useContext(TrailSystemContext)

  const [searchParams] = useSearchParams()
  const systemIds = useMemo(() => {
    const systemsParam = searchParams.get('systems')
    if (systemsParam && typeof systemsParam === 'string') {
      return systemsParam.split(',').map((x) => parseInt(x))
    }
    return []
  }, [searchParams])

  const filteredTrailSystems = useMemo(() => {
    return !systemIds.length
      ? // if no systemIds are provided, return all trail systems
        trailSystemContext.trailSystems
      : trailSystemContext.trailSystems.filter((x) => systemIds.includes(x.id))
  }, [systemIds, trailSystemContext.trailSystems])

  // scroll left and right on keydown
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      onScrollLeft()
    } else if (e.key === 'ArrowRight') {
      onScrollRight()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  // animate the scroll left and right
  const onScrollLeft = () => {
    if (trailSystemContainerRef.current) {
      trailSystemContainerRef.current.scrollBy({
        behavior: 'smooth',
        left: -(window.innerWidth >= 350 ? 252 : window.innerWidth >= 295 ? 253 : window.innerWidth - 42),
      })
    }
  }

  const onScrollRight = () => {
    if (trailSystemContainerRef.current) {
      trailSystemContainerRef.current.scrollBy({
        behavior: 'smooth',
        left: window.innerWidth >= 350 ? 252 : window.innerWidth >= 295 ? 253 : window.innerWidth - 42,
      })
    }
  }

  return (
    <>
      <FlowfeedLogoHolder>
        <Link href="/" target="_blank" display="block" paddingLeft={1}>
          <Stack direction="row" alignItems="center">
            <Stack fontSize={20}>
              <FlowfeedLogoIcon fontSize="inherit" />
            </Stack>
            <Stack fontSize={120}>
              <FlowfeedLogoText fontSize="inherit" />
            </Stack>
          </Stack>
        </Link>
      </FlowfeedLogoHolder>
      <ScrollContainer>
        <ScrollLeftContainer justifyContent="center" alignItems="center" onClick={onScrollLeft}>
          <ArrowBackIosNewIcon fontSize="inherit" />
        </ScrollLeftContainer>
        <ScrollRightContainer justifyContent="center" alignItems="center" onClick={onScrollRight}>
          <ArrowForwardIosIcon fontSize="inherit" />
        </ScrollRightContainer>
        <EmbeddedTrailSystemsContainer ref={trailSystemContainerRef}>
          <Stack direction="row" justifyContent="start" alignContent="stretch" spacing={1.5}>
            {filteredTrailSystems.map((trailSystem) => (
              <TrailSystemCardWrapper key={trailSystem.id}>
                <TrailSystemCard trailSystem={trailSystem} mini embedded />
              </TrailSystemCardWrapper>
            ))}
          </Stack>
        </EmbeddedTrailSystemsContainer>
      </ScrollContainer>
    </>
  )
}
EmbeddedTrailSystems.displayName = 'EmbeddedTrailSystems'
