import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AssistantIcon from '@mui/icons-material/Assistant'
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  darken,
  Unstable_Grid2 as Grid,
  IconButton,
  Link,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'

import { mergeWith } from 'lodash'
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'

import { useTrailFinderQuestions } from '../hooks'
import { AffectedFilters, Difficulties, StatusEnum, TrailFeature, TrailFinderAnswer } from '../models'
import { TrailSystemContext, trailSystemFilter, TrailSystemFilterParams } from '../providers'
import { getObjectKeyFromValue } from '../utils'

const TrailFinderSponsorLogo = styled('img')(({ theme }) => ({
  maxHeight: theme.spacing(12),
  maxWidth: '100%',
}))

const AffectedFilterSummaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  ['& .MuiTypography-root']: {
    letterSpacing: 0,
  },
  '&:hover': {
    backgroundColor: darken(theme.palette.grey[800], 0.1),
    color: 'inherit',
  },
  backgroundColor: theme.palette.grey[800],
  borderRadius: 9999,
  height: '200px',
  maxWidth: '100%',
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
  textTransform: 'none',
  width: '200px',
}))

const SummaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  maxWidth: 500,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}))

export const TrailFinder: FC = () => {
  const trailSystemContext = useContext(TrailSystemContext)
  const [activeStep, setActiveStep] = useState(0)
  const [answers, setAnswers] = useState<Record<number, TrailFinderAnswer>>({})
  const [answerFilters, setAnswerFilters] = useState<TrailSystemFilterParams>({})
  const [matchMap, setMatchMap] = useState<
    Record<string, { value: TrailSystemFilterParams[keyof TrailSystemFilterParams]; total: number }>
  >({})
  const [exactMatchTotal, setExactMatchTotal] = useState<number>(0)

  const trailFinderQuestions = useTrailFinderQuestions()

  const isLocationRequired = useMemo(() => {
    return trailFinderQuestions.data.some((question) =>
      question.answers?.some((answer) => answer.affectedFilters?.radius),
    )
  }, [trailFinderQuestions.data])

  const steps: {
    label: string
    answers?: TrailFinderAnswer[]
  }[] = useMemo(
    () => [
      {
        label: 'Discover your new favorite trail',
      },
      ...trailFinderQuestions.data
        .filter((question) =>
          !isLocationRequired || (isLocationRequired && trailSystemContext.userLocApproved)
            ? true
            : // filter out radius related questions if user has not approved location
              !question.answers?.some((answer) => answer.affectedFilters?.radius),
        )
        .map((question) => ({
          answers: question.answers,
          label: question.question,
        })),
    ],
    [trailFinderQuestions.data, isLocationRequired],
  )

  useEffect(() => {
    if (activeStep === 0) {
      setAnswers({})
      setMatchMap({})
    } else if (activeStep === steps.length) {
      // the affected filters object is a merge of the filters from each answer
      const mergedAffectedFilters = Object.values(answers).reduce((acc: AffectedFilters, answer) => {
        return mergeWith(acc, answer.affectedFilters, (objValue, srcValue) => {
          if (Array.isArray(srcValue) && Array.isArray(objValue)) {
            return objValue.concat(srcValue.filter((value) => !objValue.includes(value)))
          }
        })
      }, {})

      // radius is initially an array from the answers, but we only filter by the max radius
      const newAnswerFilters: TrailSystemFilterParams = {
        ...(mergedAffectedFilters.difficulties ? { difficultyIds: mergedAffectedFilters.difficulties } : undefined),
        ...(mergedAffectedFilters.features ? { featureIds: mergedAffectedFilters.features } : undefined),
        ...(mergedAffectedFilters.radius ? { radius: Math.max(...mergedAffectedFilters.radius) } : undefined),
        ...(mergedAffectedFilters.statuses ? { statuses: mergedAffectedFilters.statuses } : undefined),
        ...(mergedAffectedFilters.surfaceTypes ? { surfaceTypes: mergedAffectedFilters.surfaceTypes } : undefined),
      }

      setAnswerFilters(newAnswerFilters)

      const newMatchMap = Object.entries(newAnswerFilters)
        .map(([filterKey, filterValue]) => {
          const filteredTrailSystems = !Array.isArray(trailSystemContext.query?.data)
            ? []
            : trailSystemFilter(
                trailSystemContext.query.data,
                {
                  [filterKey]: filterValue,
                },
                trailSystemContext.userCoords,
              )
          const total = filteredTrailSystems.reduce((acc, system) => acc + (system.trails?.length || 0), 0)
          return { key: filterKey, total, value: filterValue }
        })
        .reduce(
          (acc, { key, value, total }) => ({
            ...acc,
            [key]: { total, value },
          }),
          {},
        )
      setMatchMap(newMatchMap)

      const exactMatchFilteredTrailSystems = !Array.isArray(trailSystemContext.query?.data)
        ? []
        : trailSystemFilter(trailSystemContext.query.data, newAnswerFilters, trailSystemContext.userCoords)
      setExactMatchTotal(exactMatchFilteredTrailSystems.reduce((acc, system) => acc + (system.trails?.length || 0), 0))
    }
  }, [activeStep])

  const allTrailFeatures: Record<number, TrailFeature> = useMemo(
    () =>
      !allTrailFeatures && !answerFilters.featureIds
        ? // don't bother calculating trail features if there are no features to filter by
          {}
        : (trailSystemContext.query?.data || [])
            .flatMap((ts) => ts.trails)
            .flatMap((t) => t?.features)
            .filter(Boolean)
            .reduce(
              (acc: Record<number, TrailFeature>, f) => ({
                ...acc,
                ...(f?.id && { [f.id]: f }),
              }),
              {},
            ),
    [trailSystemContext.query?.data, answerFilters.featureIds],
  )

  const formatFilterValues = useCallback(
    (key: string, values: TrailSystemFilterParams[keyof TrailSystemFilterParams]) => {
      if (key === 'radius') {
        return `Within ${values} miles`
      } else if (key === 'statuses' && Array.isArray(values)) {
        return values.map((value) => getObjectKeyFromValue(StatusEnum, value))
      } else if (key === 'surfaceTypes' && Array.isArray(values)) {
        return values
      } else if (key === 'difficultyIds' && Array.isArray(values)) {
        return values.map((value) => getObjectKeyFromValue(Difficulties, value))
      } else if (key === 'featureIds' && Array.isArray(values)) {
        return values.map((value) => allTrailFeatures[+value]?.name)
      }
      return values
    },
    [allTrailFeatures],
  )

  return (
    <>
      <Helmet>
        <title>Flowfeed - Trail Finder</title>
        <meta property="og:title" content="Flowfeed - Trail Finder" />
        <meta name="twitter:title" content="Flowfeed - Trail Finder" />
      </Helmet>
      <Stack justifyContent="center" alignItems="center" spacing={7}>
        <Box width="100%">
          <Button href="/" color="secondary">
            <Typography textTransform="none">View All Trails</Typography>
          </Button>
        </Box>
        <Box minHeight={activeStep !== 0 ? '60vh' : undefined}>
          <Stack spacing={activeStep !== steps.length ? 7 : 3} width="100%" justifyContent="center" alignItems="center">
            <Typography variant="h1" fontSize={30} textAlign="center" letterSpacing={0}>
              {activeStep <= steps.length - 1
                ? steps[activeStep].label
                : 'Based on your answers, we think your ideal trails would look like:'}
            </Typography>
            {activeStep === 0 ? (
              <Stack spacing={2} width="100%" maxWidth={390}>
                <Button onClick={() => setActiveStep(1)} variant="contained" color="secondary" size="large">
                  Let&apos;s Get Started
                </Button>
                {isLocationRequired && !trailSystemContext.userLocApproved && (
                  <Typography variant="caption" textAlign="center">
                    Some questions will be excluded. Location access is required to provide accurate trail
                    recommendations.
                  </Typography>
                )}
              </Stack>
            ) : activeStep <= steps.length - 1 ? (
              <Stack spacing={2} width="100%" maxWidth={390}>
                {steps[activeStep].answers?.map((answer) => (
                  <Button
                    key={answer.id}
                    onClick={() => {
                      setAnswers((prevAnswers) => ({ ...prevAnswers, [activeStep]: answer }))
                      setActiveStep((prevStep) => prevStep + 1)
                    }}
                    variant="contained"
                    color={answers[activeStep]?.answer === answer.answer ? 'primary' : 'secondary'}
                    size="medium"
                  >
                    {answer.answer}
                  </Button>
                ))}
              </Stack>
            ) : null}
            {activeStep === steps.length && (
              <Stack spacing={2} alignItems="center" width="100%">
                <Grid container spacing={4} justifyContent="center">
                  {Object.entries(answerFilters).map(([filterKey, filterValue]) => {
                    const formattedFilterValues = formatFilterValues(filterKey, filterValue)
                    return !matchMap[filterKey] ? null : (
                      <Grid key={filterKey}>
                        <Tooltip
                          title={
                            Array.isArray(formattedFilterValues)
                              ? formattedFilterValues.join(', ')
                              : formattedFilterValues
                          }
                        >
                          <AffectedFilterSummaryButton
                            href={`/?${
                              {
                                difficultyIds: 'difficulties',
                                featureIds: 'features',
                                radius: 'radius',
                                statuses: 'statuses',
                                surfaceTypes: 'surfaceTypes',
                              }[filterKey]
                            }=${encodeURIComponent(JSON.stringify(filterValue))}`}
                            color="secondary"
                          >
                            <Stack alignItems="center" justifyContent="space-between" height="100%">
                              {filterKey === 'radius' ? (
                                <>
                                  <Typography component="h2" variant="h6">
                                    Radius
                                  </Typography>
                                  <Typography>{formattedFilterValues}</Typography>
                                </>
                              ) : Array.isArray(formattedFilterValues) ? (
                                <>
                                  <Typography component="h2" variant="h6">
                                    {
                                      {
                                        difficultyIds: 'Difficulty',
                                        featureIds: 'Features',
                                        statuses: 'Statuses',
                                        surfaceTypes: 'Surface Types',
                                      }[filterKey]
                                    }
                                  </Typography>
                                  <Stack alignItems="center">
                                    {formattedFilterValues.slice(0, 3).map((value) => (
                                      <Typography key={value}>{value}</Typography>
                                    ))}
                                    {formattedFilterValues.length > 3 && (
                                      <Typography>+{formattedFilterValues.length - 3} more</Typography>
                                    )}
                                  </Stack>
                                </>
                              ) : null}
                              <Stack alignItems="center">
                                <Typography variant="body2">{matchMap[filterKey]?.total}</Typography>
                                <Typography variant="body2">Matches</Typography>
                              </Stack>
                            </Stack>
                          </AffectedFilterSummaryButton>
                        </Tooltip>
                      </Grid>
                    )
                  })}
                </Grid>
                {exactMatchTotal === 0 ? (
                  <>
                    <Typography variant="body1" textAlign="center">
                      No exact matches found
                    </Typography>
                    <Alert icon={<AssistantIcon />} severity="info">
                      Sorry, we couldn&apos;t find an exact match for you. Try checking out some of the partial matches
                      above!
                    </Alert>
                  </>
                ) : (
                  <>
                    <Typography variant="body1" textAlign="center">
                      {exactMatchTotal} exact matches found
                    </Typography>
                    <SummaryButton
                      href={`/?${Object.entries(answerFilters)
                        .map(
                          ([filterKey, filterValue]) =>
                            `${
                              {
                                difficultyIds: 'difficulties',
                                featureIds: 'features',
                                radius: 'radius',
                                statuses: 'statuses',
                                surfaceTypes: 'surfaceTypes',
                              }[filterKey]
                            }=${encodeURIComponent(JSON.stringify(filterValue))}`,
                        )
                        .join('&')}`}
                      variant="contained"
                      color="success"
                      size="large"
                    >
                      <Stack direction={{ tiny: 'row', xs: 'column' }} alignItems="center" component="span" spacing={1}>
                        <span>Check out</span>
                        <span>your trails!</span>
                      </Stack>
                    </SummaryButton>
                  </>
                )}
                <Typography variant="body2" textAlign="center">
                  - or -
                </Typography>
                <SummaryButton onClick={() => setActiveStep(0)} variant="contained" color="secondary" size="large">
                  Start Over
                </SummaryButton>
              </Stack>
            )}
          </Stack>
        </Box>
        {activeStep === 0 ? (
          <Box textAlign="center">
            <Link href="https://www.visitbentonville.com/" target="_blank" rel="noreferrer" display="inline-block">
              <TrailFinderSponsorLogo
                src="https://wp-flowfeed.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/02/26215533/VB-White-1.png"
                alt="Visit Bentonville"
              />
            </Link>
          </Box>
        ) : (
          <Box maxWidth={500} width="100%">
            <Stack direction="row" justifyContent="space-between" alignItems="center" minHeight={44}>
              <Box minWidth={64}>
                <IconButton
                  aria-label="back"
                  onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                  color="secondary"
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
              </Box>
              <Typography>
                {activeStep === steps.length ? 'Summary' : `${activeStep} of ${steps.length - 1}`}
              </Typography>
              <Box minWidth={64} textAlign="right">
                {activeStep === steps.length ? null : (
                  <>
                    {!answers[activeStep] && (
                      <Button
                        onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                        variant="text"
                        color="secondary"
                      >
                        <Typography textTransform="none">Skip</Typography>
                      </Button>
                    )}
                    {!!answers[activeStep] && (
                      <IconButton
                        aria-label="next"
                        onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                        color="secondary"
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </Box>
            </Stack>
          </Box>
        )}
      </Stack>
    </>
  )
}
TrailFinder.displayName = 'TrailFinder'
