import { Box, Container, Link, Stack, styled, Typography } from '@mui/material'

import { FC, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { FlowfeedLogoIcon, FlowfeedLogoText } from '../icons'
import { AppNav, Footer } from '../index'

const noLayoutRoutes = ['/embed']

const StyledPageContent = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: 1550,
  minHeight: '100%',
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
  width: '90%',
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '100%',
  },
  [theme.breakpoints.down('mobile')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.down('tiny')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}))

const TaglineBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    textAlign: 'left',
  },
  textAlign: 'center',
}))

type AppLayoutProps = {
  children: React.ReactNode
}

export const AppLayout: FC<AppLayoutProps> = (props) => {
  const location = useLocation()

  const noLayout = useMemo(() => noLayoutRoutes.includes(location.pathname), [location.pathname])

  return noLayout ? (
    props.children
  ) : (
    <Stack alignItems="center" minHeight="100vh" justifyContent={noLayout ? 'start' : 'center'} width="100%">
      <StyledContainer maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={{ sm: 2 }} width="100%">
          <Box width={{ lg: '33%', md: '46%' }}>
            <Link href="/">
              <Stack direction="row" alignItems="center">
                <Stack fontSize={{ sm: 100, tiny: '18.5vw', xl: 120, xs: '17.75vw' }}>
                  <FlowfeedLogoIcon fontSize="inherit" />
                </Stack>
                <Stack fontSize={{ sm: 300, tiny: '54.5vw', xl: 360, xs: '53.25vw' }}>
                  <FlowfeedLogoText fontSize="inherit" />
                </Stack>
              </Stack>
            </Link>
          </Box>
          <TaglineBox width={{ lg: '33%', md: '47%' }}>
            <Typography variant="overline" letterSpacing=".25em">
              REAL TRAILS. REAL CONDITIONS. REAL TIME.
            </Typography>
          </TaglineBox>
          <Stack direction="row" width={{ lg: '33%', md: '7%' }} justifyContent="end">
            <AppNav />
          </Stack>
        </Stack>
      </StyledContainer>

      <StyledPageContent>{props.children}</StyledPageContent>

      <Footer />
    </Stack>
  )
}
AppLayout.displayName = 'AppLayout'
