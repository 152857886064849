import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardActionArea,
  CardContent,
  Unstable_Grid2 as Grid,
  IconButton,
  lighten,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material'

import Decimal from 'decimal.js'
import { FC, useContext, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useParams } from 'react-router-dom'

import { AppAlert, TrailCard, TrailDataFooter, TrailDetail, TrailFilterBar, WeatherDisplay } from '../components'
import { TrailSystemContext } from '../providers'
import { USStates } from './../models'
import { getObjectKeyFromValue } from './../utils'
import { NotFound } from './NotFound'

const BackButton = styled(IconButton)<ButtonProps>(({ theme }) => ({
  ':hover': {
    color: theme.palette.primary.main,
  },
  background: lighten(theme.palette.grey[900], 0.025),
  color: theme.palette.grey[500],
}))

const ParkingIconCircle = styled(LocalParkingIcon)({
  border: '1px solid',
  borderRadius: '50%',
  height: 22,
  padding: 4,
  width: 22,
})

const DesktopOnly = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const StyledCard = styled(Card)(() => ({
  '& .MuiCardActionArea-root': {
    height: '100%',
  },
  height: '100%',
}))

const SponsorLogo = styled('img')({
  maxHeight: 200,
  maxWidth: '100%',
})

export const TrailSystemDetail: FC = () => {
  const theme = useTheme()
  const location = useLocation()
  const { systemPermalink, trailPermalink } = useParams()
  const trailSystemContext = useContext(TrailSystemContext)

  // look up the trail system from the raw data in case current filter is hiding it
  const trailSystem = useMemo(() => {
    return trailSystemContext.query?.data?.find((trailSystem) => trailSystem.permalink === systemPermalink)
  }, [systemPermalink, trailSystemContext.query?.data])

  const trail = useMemo(() => {
    return trailSystem?.trails?.find((trail) => trail.permalink === trailPermalink)
  }, [trailPermalink, trailSystem])

  // look up the trails from the filtered results
  const trails = useMemo(() => {
    return (
      trailSystemContext.trailSystems?.find((trailSystem) => trailSystem.permalink === systemPermalink)?.trails || []
    )
  }, [systemPermalink, trailSystemContext.trailSystems])

  const milesOfTrails = useMemo(() => {
    return trails.reduce((acc, trail) => acc + trail.distance, 0)
  }, [trails])

  const pageTitle = useMemo(
    () =>
      `Flowfeed - ${trailSystem?.id ? (trail?.id ? `${trailSystem.name} - ${trail.name}` : trailSystem.name) : 'Not Found'}`,
    [trail, trailSystem],
  )

  return !trailSystem ? (
    <NotFound message="Trail System Not Found" />
  ) : (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      {!!trail?.id && <TrailDetail trail={trail} trailSystem={trailSystem} />}
      <Stack justifyContent="stretch" spacing={2} width="100%">
        <Box>
          <BackButton size="small" aria-label="back" href={`/${location.search}`}>
            <KeyboardArrowLeftIcon />
          </BackButton>
        </Box>
        <Stack justifyContent="stretch" spacing={1}>
          <Stack direction={{ sm: 'row' }} justifyContent="space-between" alignItems="flex-start">
            <Stack spacing={1}>
              <Typography variant="h1">{trailSystem.name}</Typography>
              <Typography variant="body2">
                {[trailSystem.city, getObjectKeyFromValue(USStates, trailSystem.state)].join(', ')}
                <br />
                {new Decimal(milesOfTrails).toDecimalPlaces(1).toNumber()} miles of trails
              </Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  startIcon={<ParkingIconCircle />}
                  variant="text"
                  href={`https://www.google.com/maps/search/?api=1&query=${trailSystem.parkingGpsLat},${trailSystem.parkingGpsLong}`}
                  target="_blank"
                  color="secondary"
                  sx={{
                    fontSize: 14,
                  }}
                >
                  <DesktopOnly>Parking</DesktopOnly>
                </Button>
                <Button
                  startIcon={<RoomOutlinedIcon />}
                  variant="text"
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    `${[trailSystem.street, trailSystem.city, trailSystem.state].join()}`,
                  )}`}
                  target="_blank"
                  color="secondary"
                  sx={{
                    fontSize: 14,
                  }}
                >
                  <DesktopOnly>Directions</DesktopOnly>
                </Button>
              </Stack>
            </Stack>
            {trailSystem.mostRecentDisplayWeatherRecord && (
              <WeatherDisplay weather={trailSystem.mostRecentDisplayWeatherRecord} />
            )}
          </Stack>
          <TrailFilterBar trailSystemId={trailSystem.id} />
          <AppAlert />
          <Grid
            container
            justifyContent="center"
            spacing={1.5}
            // compensate for spacing around cards on outer edges to align with filter bar
            style={{ marginLeft: theme.spacing(-0.75), marginRight: theme.spacing(-0.75) }}
          >
            {!!trailSystem.sponsor?.id && (
              <Grid xs={12} tiny={6} mobile={4} lg={3} xl={2}>
                <StyledCard>
                  <CardActionArea href={`//${trailSystem.sponsor.url}`} target="_blank">
                    <Stack justifyContent="center" width="100%" height="100%">
                      <CardContent>
                        <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                          {!!trailSystem.sponsor.logoUrl && (
                            <SponsorLogo alt={`${trailSystem.sponsor.name} logo`} src={trailSystem.sponsor.logoUrl} />
                          )}
                          {!trailSystem.sponsor.logoUrl && <span>Powered by {trailSystem.sponsor.name}</span>}
                        </Stack>
                      </CardContent>
                    </Stack>
                  </CardActionArea>
                </StyledCard>
              </Grid>
            )}
            {trails.map((trail) => (
              <Grid xs={12} tiny={6} mobile={4} lg={3} xl={2} key={trail.id}>
                <TrailCard trail={trail} trailSystem={trailSystem} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
      <TrailDataFooter />
    </>
  )
}
TrailSystemDetail.displayName = 'TrailSystemDetail'
