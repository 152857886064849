import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const ScatteredCloudsDayIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <g id="SUN">
      <circle fill="#FFDC64" cx="35.2" cy="32" r="27.7" />
    </g>
    <g id="CLOUDS">
      <path
        fill="#F0F0F0"
        d="M8.1,40c0.3,0,0.6,0,1,0.1c0,0,0,0,0-0.1c0-7.6,6.2-13.8,13.8-13.8c5.4,0,10.1,3.1,12.4,7.6	c1.2-0.5,2.4-0.7,3.8-0.7c5.7,0,10.4,4.6,10.4,10.4S44.8,53.8,39,53.8H8.1c-3.8,0-6.9-3.1-6.9-6.9C1.2,43.1,4.3,40,8.1,40z"
      />
    </g>
  </g>,
  'ScatteredCloudsDayIcon',
)
ScatteredCloudsDayIcon.defaultProps = {
  viewBox: '0 0 64 64',
}
ScatteredCloudsDayIcon.displayName = 'ScatteredCloudsDayIcon'
