import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const RainNightIcon: FC<SvgIconProps> = createSvgIcon(
  <g>
    <g id="MOON">
      <circle fill="#BFE5EF" cx="46.1" cy="15.1" r="12.1" />
    </g>
    <g id="CLOUDS">
      <path
        fill="#F0F0F0"
        d="M9.9,24.1c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0-10,8.1-18.2,18.2-18.2c7.1,0,13.2,4.1,16.2,10	c1.5-0.6,3.2-1,5-1c7.5,0,13.6,6.1,13.6,13.6s-6.1,13.6-13.6,13.6l0,0H9.9c-5,0-9.1-4.1-9.1-9.1C0.8,28.1,4.9,24.1,9.9,24.1z"
      />
    </g>
    <g id="Layer_3">
      <path
        fill="#F0F0F0"
        d="M45.4,53c0,1.7-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2c0-1.7,3.2-6.7,3.2-6.7S45.4,51.3,45.4,53z"
      />
      <path
        fill="#F0F0F0"
        d="M35.5,57.9c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,3.2-6.7,3.2-6.7	S35.5,56.2,35.5,57.9z"
      />
      <path
        fill="#F0F0F0"
        d="M24.9,52.4c0,1.7-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2c0-1.7,3.2-6.7,3.2-6.7S24.9,50.6,24.9,52.4z"
      />
    </g>
  </g>,
  'RainNightIcon',
)
RainNightIcon.defaultProps = {
  viewBox: '0 0 64 64',
}
RainNightIcon.displayName = 'RainNightIcon'
