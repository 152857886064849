import { Button, Stack, StackProps, styled, Typography } from '@mui/material'

import { FC } from 'react'
import { Helmet } from 'react-helmet'

const NotFoundContainer = styled(Stack)<StackProps>({
  flexGrow: 1,
  height: '100%',
  width: '100%',
})

type NotFoundProps = {
  message?: string
}

export const NotFound: FC<NotFoundProps> = (props) => {
  return (
    <>
      <Helmet>
        <title>Flowfeed - Not Found</title>
        <meta property="og:title" content="Flowfeed - Not Found" />
        <meta name="twitter:title" content="Flowfeed - Not Found" />
      </Helmet>
      <NotFoundContainer justifyContent="center" alignItems="center">
        <Typography component="span" variant="h1" fontSize={40}>
          !?
        </Typography>
        <Typography component="span" variant="h1" marginBottom={1}>
          ¯\_(ツ)_/¯
        </Typography>
        <Typography variant="h1" fontSize={75}>
          404
        </Typography>
        <Typography marginBottom={2}>{props.message || 'Page not found'}</Typography>
        <Button href="/" variant="contained" color="secondary">
          Take me home
        </Button>
      </NotFoundContainer>
    </>
  )
}
NotFound.displayName = 'NotFound'
