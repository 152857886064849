export const weatherConditions = [
  {
    description: 'Thunderstorm with light rain',
    id: 1,
    name: 'Thunderstorm',
    openweatherConditionId: 200,
  },
  {
    description: 'Thunderstorm with rain',
    id: 2,
    name: 'Thunderstorm',
    openweatherConditionId: 201,
  },
  {
    description: 'Thunderstorm with heavy rain',
    id: 3,
    name: 'Thunderstorm',
    openweatherConditionId: 202,
  },
  {
    description: 'Light thunderstorm',
    id: 4,
    name: 'Thunderstorm',
    openweatherConditionId: 210,
  },
  {
    description: 'Thunderstorm',
    id: 5,
    name: 'Thunderstorm',
    openweatherConditionId: 211,
  },
  {
    description: 'Heavy thunderstorm',
    id: 6,
    name: 'Thunderstorm',
    openweatherConditionId: 212,
  },
  {
    description: 'Ragged thunderstorm',
    id: 7,
    name: 'Thunderstorm',
    openweatherConditionId: 221,
  },
  {
    description: 'Thunderstorm with light drizzle',
    id: 8,
    name: 'Thunderstorm',
    openweatherConditionId: 230,
  },
  {
    description: 'Thunderstorm with drizzle',
    id: 9,
    name: 'Thunderstorm',
    openweatherConditionId: 231,
  },
  {
    description: 'Thunderstorm with heavy drizzle',
    id: 10,
    name: 'Thunderstorm',
    openweatherConditionId: 232,
  },
  {
    description: 'Light intensity drizzle',
    id: 11,
    name: 'Drizzle',
    openweatherConditionId: 300,
  },
  {
    description: 'Drizzle',
    id: 12,
    name: 'Drizzle',
    openweatherConditionId: 301,
  },
  {
    description: 'Heavy intensity drizzle',
    id: 13,
    name: 'Drizzle',
    openweatherConditionId: 302,
  },
  {
    description: 'Light intensity drizzle rain',
    id: 14,
    name: 'Drizzle',
    openweatherConditionId: 310,
  },
  {
    description: 'Drizzle rain',
    id: 15,
    name: 'Drizzle',
    openweatherConditionId: 311,
  },
  {
    description: 'Heavy intensity drizzle rain',
    id: 16,
    name: 'Drizzle',
    openweatherConditionId: 312,
  },
  {
    description: 'Shower rain and drizzle',
    id: 17,
    name: 'Drizzle',
    openweatherConditionId: 313,
  },
  {
    description: 'Heavy shower rain and drizzle',
    id: 18,
    name: 'Drizzle',
    openweatherConditionId: 314,
  },
  {
    description: 'Shower drizzle',
    id: 19,
    name: 'Drizzle',
    openweatherConditionId: 321,
  },
  {
    description: 'Light rain',
    id: 20,
    name: 'Rain',
    openweatherConditionId: 500,
  },
  {
    description: 'Moderate rain',
    id: 21,
    name: 'Rain',
    openweatherConditionId: 501,
  },
  {
    description: 'Heavy intensity rain',
    id: 22,
    name: 'Rain',
    openweatherConditionId: 502,
  },
  {
    description: 'Very heavy rain',
    id: 23,
    name: 'Rain',
    openweatherConditionId: 503,
  },
  {
    description: 'Extreme rain',
    id: 24,
    name: 'Rain',
    openweatherConditionId: 504,
  },
  {
    description: 'Freezing rain',
    id: 25,
    name: 'Rain',
    openweatherConditionId: 511,
  },
  {
    description: 'Light intensity shower rain',
    id: 26,
    name: 'Rain',
    openweatherConditionId: 520,
  },
  {
    description: 'Shower rain',
    id: 27,
    name: 'Rain',
    openweatherConditionId: 521,
  },
  {
    description: 'Heavy intensity shower rain',
    id: 28,
    name: 'Rain',
    openweatherConditionId: 522,
  },
  {
    description: 'Ragged shower rain',
    id: 29,
    name: 'Rain',
    openweatherConditionId: 531,
  },
  {
    description: 'Light snow',
    id: 30,
    name: 'Snow',
    openweatherConditionId: 600,
  },
  {
    description: 'Snow',
    id: 31,
    name: 'Snow',
    openweatherConditionId: 601,
  },
  {
    description: 'Heavy snow',
    id: 32,
    name: 'Snow',
    openweatherConditionId: 602,
  },
  {
    description: 'Sleet',
    id: 33,
    name: 'Snow',
    openweatherConditionId: 611,
  },
  {
    description: 'Light shower sleet',
    id: 34,
    name: 'Snow',
    openweatherConditionId: 612,
  },
  {
    description: 'Shower sleet',
    id: 35,
    name: 'Snow',
    openweatherConditionId: 613,
  },
  {
    description: 'Light rain and snow',
    id: 36,
    name: 'Snow',
    openweatherConditionId: 615,
  },
  {
    description: 'Rain and snow',
    id: 37,
    name: 'Snow',
    openweatherConditionId: 616,
  },
  {
    description: 'Light shower snow',
    id: 38,
    name: 'Snow',
    openweatherConditionId: 620,
  },
  {
    description: 'Shower snow',
    id: 39,
    name: 'Snow',
    openweatherConditionId: 621,
  },
  {
    description: 'Heavy shower snow',
    id: 40,
    name: 'Snow',
    openweatherConditionId: 622,
  },
  {
    description: 'Mist',
    id: 41,
    name: 'Mist',
    openweatherConditionId: 701,
  },
  {
    description: 'Smoke',
    id: 42,
    name: 'Smoke',
    openweatherConditionId: 711,
  },
  {
    description: 'Sand/Dust whirls',
    id: 43,
    name: 'Dust',
    openweatherConditionId: 731,
  },
  {
    description: 'Fog',
    id: 44,
    name: 'Fog',
    openweatherConditionId: 741,
  },
  {
    description: 'Sand',
    id: 45,
    name: 'Sand',
    openweatherConditionId: 751,
  },
  {
    description: 'Dust',
    id: 46,
    name: 'Dust',
    openweatherConditionId: 761,
  },
  {
    description: 'Volcanic ash',
    id: 47,
    name: 'Ash',
    openweatherConditionId: 762,
  },
  {
    description: 'Squalls',
    id: 48,
    name: 'Squall',
    openweatherConditionId: 771,
  },
  {
    description: 'Tornado',
    id: 49,
    name: 'Tornado',
    openweatherConditionId: 781,
  },
  {
    description: 'Clear sky',
    id: 50,
    name: 'Clear',
    openweatherConditionId: 800,
  },
  {
    description: 'A Few clouds',
    id: 51,
    name: 'Clouds',
    openweatherConditionId: 801,
  },
  {
    description: 'Scattered clouds',
    id: 52,
    name: 'Clouds',
    openweatherConditionId: 802,
  },
  {
    description: 'Broken clouds',
    id: 53,
    name: 'Clouds',
    openweatherConditionId: 803,
  },
  {
    description: 'Overcast',
    id: 54,
    name: 'Clouds',
    openweatherConditionId: 804,
  },
  {
    description: 'Haze',
    id: 55,
    name: 'Haze',
    openweatherConditionId: 721,
  },
]
