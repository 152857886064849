import AddIcon from '@mui/icons-material/Add'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import CloseIcon from '@mui/icons-material/Close'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  IconButton,
  IconButtonProps,
  Link,
  Stack,
  styled,
  Tooltip,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material'

import { FC, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useToggle } from 'react-use'

import { DifficultyIcon } from '../'
import { getObjectKeyFromValue } from '../../utils'
import { Difficulties, StatusDescriptions, StatusEnum, Trail, TrailSystem } from './../../models'

const TrailDetailDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'hasFeaturedImage',
})<
  DialogProps & {
    hasFeaturedImage: boolean
  }
>(({ theme, hasFeaturedImage }) => ({
  '& .MuiDialog-paper': {
    background: theme.palette.bright.main,
    borderRadius: theme.spacing(1.5),
    color: theme.palette.bright.contrastText,
    height: '70vh',
    maxHeight: '95%',
    maxWidth: hasFeaturedImage ? 1000 : 500,
    width: '95%',
    [theme.breakpoints.down('desktop')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down('sm')]: {
      height: '95%',
      maxWidth: '100%',
      width: '90%',
    },
    [theme.breakpoints.down('tiny')]: {
      height: '100%',
      margin: 0,
      maxHeight: '100%',
      maxWidth: '100%',
      width: '100%',
    },
  },
}))

const TrailDetailDialogTitle = styled(DialogTitle)({
  paddingBottom: 0,
})

const TrailDetailContent = styled(DialogContent)<DialogContentProps>({
  '& .MuiTypography-root': {
    letterSpacing: 0,
  },
  /* width */
  '&::-webkit-scrollbar': {
    height: '16px',
    width: '16px',
  },
  /* Handle */
  '&::-webkit-scrollbar-thumb': {
    background: '#cbd5e0',
    border: '3px solid #edf2f7',
    borderRadius: '100vh',
  },
  /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#a0aec0',
  },
  /* Track */
  '&::-webkit-scrollbar-track': {
    background: '#edf2f7',
    borderRadius: '100vh',
  },
  // left: '50%',
  overflowY: 'auto',
  // padding: theme.spacing(3),
  // paddingBottom: theme.spacing(2),
  // position: 'absolute',
  // top: '50%',
  // transform: 'translate(-50%, -50%)',
})

const CloseButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.grey[500],
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}))

const ParkingIconCircle = styled(LocalParkingIcon)({
  border: '1px solid',
  borderRadius: '50%',
  height: 22,
  padding: 4,
  width: 22,
})

const StatusLabel = styled(Typography)<TypographyProps>({
  fontWeight: 700,
  left: '50%',
  marginTop: '-0.2em',
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(-50%, -50%)',
})

const TrailDetailValue = styled(Typography)<TypographyProps>({
  fontSize: 18,
  fontWeight: 700,
})

const TrailDetailLabel = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.grey[600],
}))

const CollapseButton = styled(Button)<ButtonProps>({
  fontSize: 18,
  fontWeight: 700,
  justifyContent: 'start',
  letterSpacing: 0,
  textTransform: 'none',
})

const FeatureList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  margin: 0,
  paddingLeft: theme.spacing(3.5),
}))

const FeaturedImage = styled('img')(({ theme }) => ({
  maxHeight: theme.spacing(59),
  maxWidth: '100%',
}))

const DataPartnerLogo = styled('img')(({ theme }) => ({
  maxHeight: 16,
  maxWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    maxHeight: 12,
  },
}))

type TrailDetailProps = {
  trail: Trail
  trailSystem: TrailSystem
}

export const TrailDetail: FC<TrailDetailProps> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const [showFeatures, toggleShowFeatures] = useToggle(true)
  const [showDescription, toggleShowDescription] = useToggle(false)

  const isFreezeThaw = useMemo(
    () =>
      props.trail.status?.status !== 0 &&
      props.trail.status?.freezeThawStatus?.status &&
      props.trail.status?.freezeThawStatus?.status > 0,
    [props.trail.status?.status, props.trail.status?.freezeThawStatus?.status],
  )

  const handleClose = useCallback(() => {
    navigate(`/trail-system/${props.trailSystem.permalink}${location.search}`)
  }, [props.trailSystem])

  return (
    <TrailDetailDialog
      hasFeaturedImage={!!props.trail.featuredImgUrl?.length}
      aria-labelledby="trail-detail-name"
      open={true}
      onClose={handleClose}
    >
      <TrailDetailDialogTitle id="trail-detail-name">
        <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
          <Typography component="span" fontSize={30} fontWeight={700}>
            {props.trail.name}
          </Typography>
          <Box paddingTop={props.trail.difficultyId !== Difficulties['Double Black'] ? 1 : 0}>
            <DifficultyIcon difficultyId={props.trail.difficultyId} fontSize={20} />
          </Box>
        </Stack>
        <CloseButton color="secondary" size="small" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </TrailDetailDialogTitle>
      <TrailDetailContent>
        <Stack
          direction={{ desktop: 'row', xs: 'column' }}
          justifyContent={{ desktop: 'space-between', xs: 'start' }}
          alignItems="start"
          spacing={2}
        >
          <Stack width={{ desktop: props.trail.featuredImgUrl?.length ? '50%' : '100%', xs: '100%' }} spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="start">
              <Box>
                <Typography component="h3" variant="body2">
                  {props.trailSystem.name}
                </Typography>
                <Typography component="p" variant="body2">
                  {Math.round(props.trail.distance * 10) / 10} miles
                </Typography>
                <Button
                  startIcon={<ParkingIconCircle />}
                  variant="text"
                  href={`https://www.google.com/maps/search/?api=1&query=${props.trailSystem.parkingGpsLat},${props.trailSystem.parkingGpsLong}`}
                  target="_blank"
                  color="inherit"
                >
                  Parking
                </Button>
              </Box>
              <Tooltip
                title={
                  isFreezeThaw
                    ? 'Soil temps are fluctuating above and below the freezing point, leading to variable conditions. Trails may be firm and rideable under 30° F.'
                    : {
                        ...StatusDescriptions,
                        0: props.trail.manualTrailStatuses?.[0].reason || StatusDescriptions[0],
                      }[props.trail.status?.status ?? -1]
                }
              >
                <Box width={{ desktop: '50%' }}>
                  <Stack justifyContent="center" alignItems="center" position="relative">
                    <CircularProgress
                      variant="determinate"
                      value={100}
                      size={95}
                      thickness={5}
                      sx={{
                        color: isFreezeThaw
                          ? theme.palette.freezeThaw.main
                          : {
                              [-1]: theme.palette.grey[600],
                              0: theme.palette.grey[300],
                              1: theme.palette.trailStatus.dark,
                              2: theme.palette.trailStatus.main,
                              3: theme.palette.trailStatus.light,
                            }[props.trail.status?.status ?? -1],
                      }}
                    />
                    <StatusLabel component="p" variant="h6" fontSize={isFreezeThaw ? 16 : undefined}>
                      {isFreezeThaw
                        ? 'Freeze Thaw'
                        : getObjectKeyFromValue(StatusEnum, props.trail.status?.status ?? -1)}
                    </StatusLabel>
                  </Stack>
                </Box>
              </Tooltip>
            </Stack>
            {props.trail.status?.status === 0 && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <DoDisturbOnIcon />
                <Typography component="p" variant="body2">
                  {props.trail.manualTrailStatuses?.[0].reason || StatusDescriptions[0]}
                </Typography>
              </Stack>
            )}
            {props.trail.status?.status === -1 && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <BrokenImageIcon />
                <Typography component="p" variant="body2">
                  {StatusDescriptions[-1]}
                </Typography>
              </Stack>
            )}
            <Stack direction="row" spacing={{ tiny: 4, xs: 2 }}>
              <Stack>
                {!!props.trail.surfaceType?.length && (
                  <>
                    <TrailDetailValue>{props.trail.surfaceType}</TrailDetailValue>
                    <TrailDetailLabel variant="caption">Surface Type</TrailDetailLabel>
                  </>
                )}
              </Stack>
              <Stack direction="row" spacing={2}>
                <Stack>
                  <TrailDetailValue>{props.trail.climb || 0} ft</TrailDetailValue>
                  <TrailDetailLabel variant="caption">Climb</TrailDetailLabel>
                </Stack>
                <Stack>
                  <TrailDetailValue>{props.trail.descent || 0} ft</TrailDetailValue>
                  <TrailDetailLabel variant="caption">Descent</TrailDetailLabel>
                </Stack>
              </Stack>
            </Stack>
            {!!props.trail.description?.length && (
              <Stack>
                <CollapseButton
                  onClick={toggleShowDescription}
                  startIcon={showDescription ? <RemoveIcon /> : <AddIcon />}
                  variant="text"
                  color="inherit"
                >
                  About
                </CollapseButton>
                <Collapse in={showDescription}>
                  <Typography
                    component="p"
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: props.trail.description }}
                  ></Typography>
                </Collapse>
              </Stack>
            )}
            {!!props.trail.features?.length && (
              <Stack>
                <CollapseButton
                  onClick={toggleShowFeatures}
                  startIcon={showFeatures ? <RemoveIcon /> : <AddIcon />}
                  variant="text"
                  color="inherit"
                >
                  Features
                </CollapseButton>
                <Collapse in={showFeatures}>
                  <FeatureList>
                    {props.trail.features.map((feature, i) => (
                      <Typography component="li" key={i} fontWeight={500}>
                        {feature.name}
                      </Typography>
                    ))}
                  </FeatureList>
                </Collapse>
              </Stack>
            )}
          </Stack>
          {!!props.trail.featuredImgUrl?.length && (
            <Stack width={{ desktop: '50%', xs: '100%' }} justifyContent="center" alignItems="center">
              <FeaturedImage src={props.trail.featuredImgUrl} alt="Trail featured image" />
            </Stack>
          )}
        </Stack>
      </TrailDetailContent>
      <DialogActions>
        <Stack
          direction={{ sm: 'row' }}
          justifyContent="space-between"
          alignItems={{ sm: 'start', xs: 'center' }}
          width="100%"
          paddingY={{ sm: 1 }}
          paddingX={{ sm: 2 }}
          spacing={{ sm: 0, xs: 1 }}
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography fontSize={{ sm: 12, xs: 10 }}>Data partner:</Typography>
            <Link href="https://wearetrailblazers.org/" target="_blank" rel="noreferrer">
              <Stack>
                <DataPartnerLogo
                  src="https://wp-flowfeed.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/02/26214834/Trailblazers-Logo_Black.png"
                  alt="Trailblazers"
                />
              </Stack>
            </Link>
          </Stack>

          <Link href="https://www.flowfeed.app/trail-data-feedback/" target="_blank" rel="noreferrer" color="inherit">
            <Typography variant="body2" fontSize={{ sm: 14, xs: 10 }}>
              Provide feedback
            </Typography>
          </Link>
        </Stack>
      </DialogActions>
    </TrailDetailDialog>
  )
}
TrailDetail.displayName = 'TrailDetail'
