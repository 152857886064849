import { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { AppLayout, ScrollToTopOnNavigate } from './components'
import { EmbeddedTrailSystems } from './pages/EmbeddedTrailSystems'
import { NotFound } from './pages/NotFound'
import { TrailFinder } from './pages/TrailFinder'
import { TrailSystemDetail } from './pages/TrailSystemDetail'
import { TrailSystems } from './pages/TrailSystems'
import { AppUpdateProvider, TrailSystemProvider } from './providers'

export const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <AppUpdateProvider>
        <TrailSystemProvider>
          <AppLayout>
            <ScrollToTopOnNavigate />
            <Routes>
              <Route path="/trail-system/:systemPermalink/:trailPermalink" element={<TrailSystemDetail />} />
              <Route path="/trail-system/:systemPermalink" element={<TrailSystemDetail />} />
              <Route path="/trail-finder" element={<TrailFinder />} />
              <Route path="/embed" element={<EmbeddedTrailSystems />} />
              <Route path="/" element={<TrailSystems />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AppLayout>
        </TrailSystemProvider>
      </AppUpdateProvider>
    </BrowserRouter>
  )
}
