import { createSvgIcon, SvgIconProps } from '@mui/material'

import { FC } from 'react'

export const FlowfeedLogoText: FC<SvgIconProps> = createSvgIcon(
  <g>
    <g>
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        d="M49.4,27V10h3.4v14.1h8.5V27C61.3,27,49.4,27,49.4,27z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        d="M80.7,18.5c0-2,0.4-3.7,1.1-5.1c0.7-1.3,1.7-2.3,2.8-3c1.2-0.6,2.6-1,4.3-1c2.4,0,4.4,0.8,5.9,2.3s2.3,3.7,2.3,6.6c0,2.9-0.8,5.1-2.4,6.7c-1.4,1.4-3.3,2.1-5.8,2.1c-2.4,0-4.4-0.7-5.8-2.1C81.5,23.6,80.7,21.4,80.7,18.5zM84.2,18.4c0,2,0.5,3.5,1.4,4.4c0.9,1,2,1.5,3.4,1.5c1.3,0,2.5-0.5,3.3-1.5s1.3-2.5,1.3-4.5c0-2-0.4-3.5-1.3-4.4s-2-1.4-3.4-1.4s-2.5,0.5-3.4,1.4C84.7,14.9,84.2,16.4,84.2,18.4z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        d="M120.3,27l-4.1-17.2h3.5l2.5,11.8l3.1-11.8h4.1l3,12l2.6-12h3.4L134.3,27h-3.6l-3.4-12.9L124,27C124,27,120.3,27,120.3,27z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        d="M190.8,27V9.8h12.5v2.9h-9.1v3.8h8.5v2.9h-8.5v4.7h9.4V27H190.8z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        d="M224.6,27V9.8h12.5v2.9H228v3.8h8.5v2.9H228v4.7h9.5V27H224.6z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        d="M258.4,9.8h6.2c1.6,0,2.8,0.1,3.6,0.4s1.5,0.8,2.2,1.5c0.7,0.7,1.2,1.6,1.6,2.7s0.6,2.5,0.6,4.1c0,1.5-0.2,2.8-0.6,3.9s-0.9,2-1.5,2.6s-1.4,1.1-2.2,1.4c-0.9,0.3-2,0.5-3.4,0.5h-6.4L258.4,9.8L258.4,9.8z M261.8,12.7v11.4h2.6c1.1,0,1.9-0.1,2.4-0.3s0.9-0.4,1.2-0.8c0.3-0.3,0.6-0.9,0.8-1.6c0.2-0.7,0.3-1.7,0.3-3c0-1.3-0.1-2.3-0.3-3c-0.2-0.7-0.5-1.3-1-1.7s-1-0.7-1.6-0.8c-0.5-0.1-1.4-0.2-2.8-0.2C263.4,12.7,261.8,12.7,261.8,12.7z"
      />
      <polygon
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        points="27.6,16.8 20.2,16.8 20.2,12.7 30.6,12.7 32.7,9.8 16.8,9.8 16.8,27 20.2,27 20.2,19.7 25.5,19.7"
      />
      <polygon
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        points="168.6,16.8 161.7,16.8 161.7,12.7 171.6,12.7 173.7,9.8 158.3,9.8 158.3,27 161.7,27 161.7,19.7 166.5,19.7"
      />
    </g>
    <g>
      <path
        fill="#FFFFFF"
        d="M283.1,27.3v-5.7h2.1c0.4,0,0.8,0.1,1.1,0.2s0.5,0.3,0.6,0.5s0.2,0.5,0.2,0.8c0,0.2-0.1,0.5-0.2,0.7s-0.3,0.4-0.6,0.5c0.3,0.1,0.6,0.3,0.8,0.5s0.3,0.5,0.3,0.9c0,0.3-0.1,0.5-0.2,0.7s-0.3,0.4-0.4,0.5s-0.4,0.2-0.6,0.3s-0.6,0.1-0.9,0.1L283.1,27.3L283.1,27.3z M283.9,24h1.2c0.3,0,0.6,0,0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.3s0.1-0.3,0.1-0.5c0-0.2,0-0.4-0.1-0.5s-0.2-0.2-0.4-0.3s-0.5-0.1-0.9-0.1h-1.1L283.9,24L283.9,24z M283.9,26.6h1.4c0.2,0,0.4,0,0.5,0c0.2,0,0.3-0.1,0.4-0.2s0.2-0.2,0.3-0.3s0.1-0.3,0.1-0.5c0-0.2-0.1-0.4-0.2-0.6s-0.3-0.3-0.5-0.3s-0.5-0.1-0.8-0.1h-1.3L283.9,26.6L283.9,26.6z"
      />
      <path fill="#FFFFFF" d="M290.1,27.3v-5.7h4.1v0.7h-3.4V24h3.2v0.7h-3.2v1.9h3.5v0.7H290.1z" />
      <path fill="#FFFFFF" d="M298.5,27.3v-5.1h-1.9v-0.7h4.5v0.7h-1.9v5.1H298.5z" />
      <path
        fill="#FFFFFF"
        d="M302.3,27.3l2.2-5.7h0.8l2.3,5.7h-0.9l-0.7-1.7h-2.4l-0.6,1.7H302.3z M303.9,25h1.9l-0.6-1.6c-0.2-0.5-0.3-0.9-0.4-1.2c-0.1,0.4-0.2,0.7-0.3,1.1L303.9,25z"
      />
    </g>
  </g>,
  'FlowfeedLogoText',
)
FlowfeedLogoText.defaultProps = {
  sx: {
    height: '0.15em',
  },
  viewBox: '0 0 324.5 36.6',
}
FlowfeedLogoText.displayName = 'FlowfeedLogoText'
