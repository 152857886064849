import { Unstable_Grid2 as Grid, Stack, useTheme } from '@mui/material'

import { FC, useContext } from 'react'

import { AppAlert, TrailDataFooter, TrailFilterBar, TrailSystemCard } from '../components'
import { TrailSystemContext } from '../providers'

export const TrailSystems: FC = () => {
  const theme = useTheme()
  const trailSystemContext = useContext(TrailSystemContext)

  return (
    <>
      <Stack justifyContent="stretch" spacing={1} width="100%">
        <TrailFilterBar />
        <AppAlert />
        <Grid
          container
          justifyContent="center"
          spacing={1.5}
          // compensate for spacing around cards on outer edges to align with filter bar
          style={{ marginLeft: theme.spacing(-0.75), marginRight: theme.spacing(-0.75) }}
        >
          {trailSystemContext.trailSystems.map((trailSystem) => (
            <Grid xs={12} tablet={6} lg={4} key={trailSystem.id}>
              <TrailSystemCard trailSystem={trailSystem} />
            </Grid>
          ))}
        </Grid>
      </Stack>
      <TrailDataFooter />
    </>
  )
}
TrailSystems.displayName = 'TrailSystems'
