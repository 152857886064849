import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import InstagramIcon from '@mui/icons-material/Instagram'
import XIcon from '@mui/icons-material/X'
import { Box, BoxProps, IconButton, Link, Stack, styled } from '@mui/material'

import { FC } from 'react'

import { FlowfeedLogoIcon } from './..'

const StyledFooterBox = styled(Box)<BoxProps>(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
}))

export const Footer: FC = () => {
  return (
    <StyledFooterBox component="footer">
      <Stack spacing={2} alignItems="center">
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
          <IconButton aria-label="Facebook" color="secondary" href="https://www.facebook.com/Flowfeed-111558151177423">
            <FacebookRoundedIcon />
          </IconButton>
          <IconButton aria-label="Instagram" color="secondary" href="https://www.instagram.com/flowfeedapp/">
            <InstagramIcon />
          </IconButton>
          <IconButton aria-label="Twitter (X)" color="secondary" href="https://twitter.com/flowfeedapp">
            <XIcon />
          </IconButton>
          <IconButton aria-label="Email" color="secondary" href="mailto:team@flowfeed.app">
            <EmailOutlinedIcon />
          </IconButton>
        </Stack>
        <Link href="/">
          <Stack fontSize={{ sm: 100, tiny: '18.5vw', xl: 120, xs: '17.75vw' }}>
            <FlowfeedLogoIcon fontSize="inherit" />
          </Stack>
        </Link>
      </Stack>
    </StyledFooterBox>
  )
}
Footer.displayName = 'Footer'
