import { createTheme, darken } from '@mui/material'
import { grey } from '@mui/material/colors'

import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  },
)
LinkBehavior.displayName = 'LinkBehavior'

declare module '@mui/material/styles' {
  interface Palette {
    bright: Palette['primary']
    freezeThaw: Palette['primary']
    gradient: Palette['primary']
    gradientLight: Palette['primary']
    trailStatus: Palette['primary']
  }

  interface PaletteOptions {
    bright: PaletteOptions['primary']
    freezeThaw: PaletteOptions['primary']
    gradient: PaletteOptions['primary']
    gradientLight: PaletteOptions['primary']
    trailStatus: PaletteOptions['primary']
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    tiny: true // custom breakpoint
    sm: true
    mobile: true // custom breakpoint
    tablet: true // custom breakpoint
    md: true
    desktop: true // custom breakpoint
    lg: true
    xl: true
  }
}

const AppTheme = createTheme({
  breakpoints: {
    values: {
      /* eslint-disable sort-keys/sort-keys-fix */
      xs: 0,
      tiny: 350, // custom breakpoint
      sm: 600,
      mobile: 700, // custom breakpoint
      tablet: 800, // custom breakpoint
      md: 900,
      desktop: 1024, // custom breakpoint
      lg: 1200,
      xl: 1536,
      /* eslint-enable sort-keys/sort-keys-fix */
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          '&:hover': {
            backgroundColor: grey[300],
            color: '#000',
          },
          backgroundColor: '#171717',
          color: grey[300],
        },
        outlinedSecondary: {
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: '#ff7900',
            color: '#ff7900',
          },
        },
        root: {
          letterSpacing: '.1rem',
        },
        sizeLarge: {
          fontSize: 20,
          fontWeight: 700,
          padding: '14px 16px',
        },
        sizeMedium: {
          fontSize: 18,
          padding: 12,
        },
        textSecondary: {
          '&:hover': {
            backgroundColor: 'transparent',
            color: '#ff7900',
          },
          color: grey[300],
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: darken(grey[800], 0.03),
          borderRadius: 12,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: 'linear-gradient(340deg, rgba(0, 0, 0, 1) 0%, rgba(41, 41, 41, 1) 73%, #363636 100%)',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
        },
      },
    },
    MuiGrid2: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorSecondary: {
          '&:hover': {
            color: '#ff7900',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        arrow: {
          color: 'rgb(32, 32, 32)',
        },
        tooltip: {
          background: 'rgb(32, 32, 32)',
          color: '#fff',
          fontSize: 13,
          letterSpacing: '.1rem',
          padding: '8px',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        letterSpacing: '.1rem',
      },
    },
  },
  palette: {
    bright: { contrastText: '#000', main: '#ffffff' },
    error: { contrastText: '#000', main: '#ec6458' },
    freezeThaw: {
      contrastText: '#000',
      main: '#75d9fe',
    },
    gradient: {
      contrastText: '#fff',
      main: 'linear-gradient(340deg, rgba(0, 0, 0, 1) 0%, rgba(41, 41, 41, 1) 73%, #4a4a4c 100%)',
    },
    gradientLight: {
      contrastText: '#fff',
      main: 'linear-gradient(340deg, rgb(32, 32, 32) 0%, rgba(41, 41, 41, 1) 73%, #4a4a4c 100%)',
    },
    info: { contrastText: '#fff', main: grey[700] },
    mode: 'dark',
    primary: { contrastText: '#000', main: '#ff7900' },
    secondary: { contrastText: '#000', main: grey[300] },
    success: { contrastText: '#fff', dark: '#166534', main: '#15803d' },
    text: {
      primary: '#fff',
      secondary: grey[300],
    },
    trailStatus: {
      dark: '#ec6458',
      light: '#8ec666',
      main: '#ffc05f',
    },
    warning: { contrastText: '#000', main: '#ffc05f' },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 42,
      fontWeight: 700,
    },
  },
})

export default AppTheme
