import AirIcon from '@mui/icons-material/Air'
import { Stack, styled, Typography } from '@mui/material'

import Decimal from 'decimal.js'
import { FC, useMemo } from 'react'

import { WeatherDisplayMore } from './..'
import { weatherConditions, WeatherRecord } from './../../models'
import { calcDayNightCycle, convertCelsiusToFahrenheit } from './../../utils'
import { WeatherIcon } from './../icons'

const HideOnTiny = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('tiny')]: {
    display: 'none',
  },
}))

type WeatherDisplayProps = {
  weather: WeatherRecord
}

export const WeatherDisplay: FC<WeatherDisplayProps> = (props) => {
  const condition = useMemo(() => {
    return weatherConditions.find((weatherCondition) => weatherCondition.id === props.weather.conditionId)
  }, [props.weather.conditionId, weatherConditions])

  return (
    condition && (
      <Stack direction={{ tiny: 'row' }} alignItems="center" spacing={4}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack fontSize={{ md: 80, sm: 60, xs: 50 }}>
            <WeatherIcon
              fontSize="inherit"
              conditionId={props.weather.conditionId}
              cycle={calcDayNightCycle(props.weather.sunrise, props.weather.sunset)}
            />
          </Stack>
          <Stack alignItems="center">
            <Typography variant="h3" fontSize={56} pl={2}>
              {Math.round(convertCelsiusToFahrenheit(props.weather.temperature))}&deg;
            </Typography>
            <Typography variant="caption" fontStyle="italic">
              Feels like{' '}
              {props.weather.feelsLike === undefined
                ? '-'
                : Math.round(convertCelsiusToFahrenheit(props.weather.feelsLike))}
              &deg;
            </Typography>
            <Typography variant="caption">{condition.description}</Typography>
          </Stack>
        </Stack>
        <Stack
          spacing={1}
          alignItems="center"
          pt={{ tiny: 2 }} // compensate for the line height of the larger temperature text
        >
          <HideOnTiny>
            <Stack direction="row" alignItems="center" spacing={1}>
              <AirIcon />
              <Typography>
                {props.weather.windSpeed ? new Decimal(props.weather.windSpeed).mul(2.2369).round().toNumber() : 0} mph
              </Typography>
            </Stack>
          </HideOnTiny>
          <HideOnTiny>
            <Typography>
              High/Low {Math.round(convertCelsiusToFahrenheit(props.weather.tempMax))}/
              {Math.round(convertCelsiusToFahrenheit(props.weather.tempMin))}&deg;
            </Typography>
          </HideOnTiny>
          <WeatherDisplayMore weather={props.weather} />
        </Stack>
      </Stack>
    )
  )
}
WeatherDisplay.displayName = 'WeatherDisplay'
